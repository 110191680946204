import { useFlags } from '@atlaskit/flag';
import { ExportCustomersDialogFormFields } from 'core/config/form-fields';
import { useDialog } from 'core/providers/DialogProvider';
import { useState } from 'react';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { showSuccessFlag } from 'core/utilities/flags-helper';
import CustomersApiService from 'core/api/customers/customers-api.service';
dayjs.extend(customParseFormat);

interface IExportCustomersDialogFormOutput {
  dateFrom: string;
  dateTo: string;
}

const ExportCustomersDialog = () => {
  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  // Hooks
  const flags = useFlags();
  const dialog = useDialog();

  // Page specifics
  const exportCustomers = async (data: IExportCustomersDialogFormOutput) => {
    setError(undefined);
    setFormSubmitting(true);
    const { dateFrom, dateTo } = data;
    if (dateFrom > dateTo) {
      setError('Created after must be before Create before');
      setFormSubmitting(false);
      return;
    }
    const csv: string = await CustomersApiService.exportCustomerData({
      dateFrom,
      dateTo,
    });
    const blob = new Blob(['\ufeff', csv]);
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `pride_hearing_customers_${dateFrom}_to_${dateTo}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    dialog?.closeDialog();
    setFormSubmitting(false);
    showSuccessFlag('Export successful', 'The customers were successfully exported into a csv file.', flags);
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4 overflow-y-auto'
        onSubmit={exportCustomers}
        fields={ExportCustomersDialogFormFields}
        buttonLabel='Submit'
        loading={formSubmitting}
        cancelButton={cancelButton}
        formErrorMessage={error}
      />
    );
  };

  return <SharedDialogBase title='Export customers' customContentTemplate={customContent()} showButtons={false} />;
};

export default ExportCustomersDialog;
