import { Permissions } from 'core/constants/permissions';
import { ReactElement } from 'react';
import {
  Calendar,
  Database,
  Folder,
  Headphones,
  Package,
  PhoneOutgoing,
  PieChart,
  ShoppingBag,
  Users,
} from 'react-feather';

export interface ISidebarOption {
  icon: ReactElement;
  label: string;
  route: string;
  requiredPermissions: string[];
}

export const SidebarOptions: ISidebarOption[] = [
  {
    icon: <PhoneOutgoing />,
    label: 'Booking',
    route: 'booking',
    requiredPermissions: [Permissions.BOOKING_DASHBOARD],
  },
  // {
  //   icon: <Smile />,
  //   label: 'Aftercare',
  //   route: 'aftercare',
  //   requiredPermissions: [Permissions.AFTERCARE_DASHBOARD],
  // },
  {
    icon: <Headphones />,
    label: 'Surveying',
    route: 'surveying',
    requiredPermissions: [Permissions.SURVEYING_GET],
  },
  {
    icon: <Package />,
    label: 'Fitting',
    route: 'fitting',
    requiredPermissions: [Permissions.FITTING_DASHBOARD],
  },
  {
    icon: <Calendar />,
    label: 'Appointments',
    route: 'appointments',
    requiredPermissions: [Permissions.APPOINTMENTS_GET],
  },
  {
    icon: <Folder />,
    label: 'Customers',
    route: 'customers',
    requiredPermissions: [Permissions.CUSTOMERS_LIST],
  },
  {
    icon: <ShoppingBag />,
    label: 'Orders',
    route: 'orders',
    requiredPermissions: [Permissions.ORDER_LIST],
  },
  {
    icon: <Users />,
    label: 'Users',
    route: 'users',
    requiredPermissions: [Permissions.USERS_CREATE],
  },
  {
    icon: <PieChart />,
    label: 'Reports',
    route: 'reports',
    requiredPermissions: [Permissions.REPORTING_GET],
  },
  {
    icon: <Database />,
    label: 'Audit log',
    route: 'audit-log',
    requiredPermissions: [Permissions.AUDIT_LOG_GET],
  },
];
