import SharedHeader from './header';
import SharedSidebar from '../sidebar/sidebar';
import { useEffect, useState } from 'react';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import SetPasswordDialog from 'modules/auth/set-password-dialog/set-password-dialog';
import { useResponsiveState } from 'core/providers/ResponsiveProvider';
import Drawer from '@atlaskit/drawer';

const SharedAdminLayout = ({ children }: any) => {
  const [screenHeight, setScreenHeight] = useState(`${window.innerHeight}px`);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));

    return () => {
      window.removeEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));
    };
  }, []);

  const { userData } = useAuthState();
  const dialog = useDialog();
  const { isTablet } = useResponsiveState();

  useEffect(() => {
    if (userData?.resetPassword) {
      dialog?.openDialog(<SetPasswordDialog />);
    }
  }, [dialog, userData?.resetPassword]);

  return (
    <div className='bg-gray-50' style={{ height: screenHeight }}>
      <SharedHeader
        title={isTablet ? 'Pride Hearing CRM Portal' : 'Pride Hearing - CRM Portal'}
        setSidebarOpen={setSidebarOpen}
      />
      <div className='h-full pt-[136px] md:pt-[64px] flex'>
        <Drawer
          onClose={() => setSidebarOpen(false)}
          isOpen={sidebarOpen}
          width='full'
          overrides={{
            Sidebar: {
              component: ({ children }) => (
                <div className='w-full'>
                  <div className='flex items-center px-3 py-3 border-b sidebar-button'>{children}</div>
                  <SharedSidebar setSidebarOpen={setSidebarOpen} />
                </div>
              ),
            },
          }}
        />
        {isTablet ? <></> : <SharedSidebar setSidebarOpen={setSidebarOpen} />}
        <div className='overflow-y-auto flex-grow px-4 lg:px-6 lg:pb-6 flex flex-col'>{children}</div>
      </div>
    </div>
  );
};

export default SharedAdminLayout;
