import { IOrder } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { AidPowerOption } from 'core/constants/hearing-aid-power-options';
import { AidType, AidTypes } from 'core/constants/hearing-aid-types';
import { getRandomColor } from 'core/utilities/color-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import { useCallback } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import OrderReportBreakdownCustomerCell, {
  IOrderReportBreakdownCustomerCell,
} from '../order-report-breakdown-custom-cell/order-report-breakdown-custom-cell';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';

const OrderBookerReport = () => {
  const processRows = useCallback(async (querySnapshot: QuerySnapshot<IOrder>) => {
    const rows: IReportBaseTableRows<IOrderReportBreakdownCustomerCell>[] = [];
    querySnapshot.forEach((doc) => {
      const { booker, left, right, grossPrice, power, customer } = doc.data();
      if (!booker) {
        return;
      }
      const { uid, fullName } = booker;
      const aids = [left, right].filter((ear) => ear);
      const aidType = left ? left.type : right!.type;
      const upgraded = aids.some((aid) => power === AidPowerOption.RECHARGEABLE || aid?.type !== AidType.ESSENTIAL)
        ? 1
        : 0;
      const rowIndex = rows.findIndex((row) => row.key === uid);
      if (rowIndex !== -1) {
        rows[rowIndex].data.valueTotal += grossPrice;
        rows[rowIndex].data.count += 1;
        rows[rowIndex].data.upgrades += upgraded;
        const typeIndex = rows[rowIndex].data.breakdowns.findIndex((type) => type.key === aidType);
        if (typeIndex !== -1) {
          rows[rowIndex].data.breakdowns[typeIndex].count += 1;
          rows[rowIndex].data.breakdowns[typeIndex].customerListRows.push({
            customer,
          });
        }
      } else {
        rows.push({
          key: uid,
          color: getRandomColor(),
          data: {
            upgrades: upgraded,
            total: querySnapshot.docs.length,
            valueTotal: grossPrice,
            label: fullName,
            count: 1,
            breakdowns: AidTypes.map((type) => ({
              key: type.value,
              count: aidType === type.value ? 1 : 0,
              label: type.label,
              customerListRows:
                aidType === type.value
                  ? [
                      {
                        customer,
                      },
                    ]
                  : [],
            })),
          },
        });
      }
    });
    return rows;
  }, []);

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: OrderReportBreakdownCustomerCell,
      id: 'booker',
    },
  ];

  const tableColumns = [{ label: 'Booker', key: 'booker', templateId: 'booker', width: 100 }];

  return (
    <>
      <PageHeader title='Booker breakdown report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
        })}
        subscriber={OrdersApiService.subscribeToOrders}
        totalLabel={'Total number of orders created:'}
        emptyText={'No orders were created during the selected time frame'}
        customTemplates={customTemplates}
      />
    </>
  );
};

export default OrderBookerReport;
