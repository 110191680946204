import { useFlags } from '@atlaskit/flag';
import { IAuditLog } from 'core/api/audit-log/audit-log-api-interface';
import AuditLogApiService from 'core/api/audit-log/audit-log-api.service';
import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { QuerySnapshot } from 'firebase/firestore';
import AuditLogTable from 'modules/audit-log/audit-log-table/audit-log-table';
import { useCallback, useEffect, useState } from 'react';

interface ICustomerOverviewAuditLogs {
  customer: IStoredCustomer;
}

const CustomerOverviewAuditLogs = ({ customer }: ICustomerOverviewAuditLogs) => {
  const [logsSnap, setLogsSnap] = useState<QuerySnapshot<IAuditLog>>();
  const flags = useFlags();
  const [loading, setLoading] = useState(true);

  const getAuditLogs = useCallback(async () => {
    try {
      setLoading(true);
      const result = await AuditLogApiService.listAll({ customer: customer.uid }, ['createdAt']);
      setLogsSnap(result);
      setLoading(false);
    } catch (error) {
      showErrorFlag('An error occurred', 'The audit logs could not be retrieved, please try again.', flags);
    }
  }, [customer.uid, flags]);

  useEffect(() => {
    getAuditLogs();
  }, [getAuditLogs]);

  return (
    <>
      <AuditLogTable logsSnap={logsSnap} dataLoading={loading} />
    </>
  );
};

export default CustomerOverviewAuditLogs;
