import { BookingStatus } from './booking-status';

export const CustomerStatus = {
  CUSTOMER_CREATED: 'customerCreated',
  SURVEY_CREATED: 'surveyCreated',
  SURVEY_LETTER_SENT: 'surveyLetterSent',
  AWAITING_TEST_BOOKING: 'awaitingTestBooking',
  NOT_INTERESTED: 'notInterested',
  TEST_CANCELLED: 'testCancelled',
  TEST_CANCELLED_REARRANGE: 'testCancelledRearrange',
  TESTED_NOT_PRESCRIBED: 'testedNotPrescribed',
  HEARING_TEST_BOOKED: 'hearingTestBooked',
  HEARING_TEST_BOOKED_PHONE_IN: 'hearingTestBookedPhoneIn',
  AWAITING_FITTING_BOOKING: 'awaitingFittingBooking',
  AWAITING_FITTING_BOOKING_SPECIAL: 'awaitingFittingBookingSpecial',
  FITTING_BOOKED: 'fittingBooked',
  FITTING_CANCELLED_REARRANGE: 'fittingCancelledRearrange',
  FITTING_CANCELLED: 'fittingCancelled',
  FITTED_AWAITING_DOC_CERT: 'fittedAwaitingDocCert',
  FITTED_AWAITING_UPLOAD: 'fittedAwaitingUpload',
  FITTED_FOLLOW_UP: 'fittedRequiresFollowUp',
  FITTING_COMPLETE: 'fittingComplete',
  AFTER_CARE_CALL: 'afterCareCall',
  AWAITING_SERVICE_BOOKING: 'awaitingServiceBooking',
  SERVICE_BOOKED: 'serviceBooked',
  SERVICE_CANCELLED_REARRANGE: 'serviceCancelledRearrange',
  SERVICE_CANCELLED: 'serviceCancelled',
  SERVICE_COMPLETE: 'serviceComplete',
  ORDER_CANCELLED: 'orderCancelled',
  WAX_REMOVAL_BOOKED: 'waxRemovalBooked',
  CLOSED: 'closed',
  CLOSED_SPREAD_PAYMENT: 'closedSpreadPayment',
  DISPUTED: 'disputed',
};

export const CustomerStatuses: ICustomerStatus[] = [
  {
    label: 'Customer Created',
    key: CustomerStatus.CUSTOMER_CREATED,
    transitionTo: [
      CustomerStatus.SURVEY_CREATED,
      CustomerStatus.SURVEY_LETTER_SENT,
      CustomerStatus.AWAITING_TEST_BOOKING,
      CustomerStatus.WAX_REMOVAL_BOOKED,
      CustomerStatus.AWAITING_SERVICE_BOOKING,
    ],
  },
  {
    label: 'Survey Created',
    key: CustomerStatus.SURVEY_CREATED,
    transitionTo: [CustomerStatus.SURVEY_LETTER_SENT],
  },
  {
    label: 'Survey Letter Sent',
    key: CustomerStatus.SURVEY_LETTER_SENT,
    transitionTo: [CustomerStatus.AWAITING_TEST_BOOKING, CustomerStatus.HEARING_TEST_BOOKED_PHONE_IN],
  },
  {
    label: 'Awaiting Test Booking',
    key: CustomerStatus.AWAITING_TEST_BOOKING,
    transitionTo: [
      BookingStatus.FIRST_ATTEMPT,
      BookingStatus.SECOND_ATTEMPT,
      BookingStatus.THIRD_ATTEMPT,
      BookingStatus.FOURTH_ATTEMPT,
      BookingStatus.EMAIL_SMS,
      BookingStatus.CALLBACK_LATER,
      CustomerStatus.NOT_INTERESTED,
      CustomerStatus.HEARING_TEST_BOOKED,
      CustomerStatus.HEARING_TEST_BOOKED_PHONE_IN,
      CustomerStatus.DISPUTED,
    ],
  },
  {
    label: 'Disputed',
    key: CustomerStatus.DISPUTED,
    transitionTo: [CustomerStatus.AWAITING_TEST_BOOKING, CustomerStatus.HEARING_TEST_BOOKED, CustomerStatus.CLOSED],
  },
  {
    label: 'Not Interested',
    key: CustomerStatus.NOT_INTERESTED,
    transitionTo: [
      CustomerStatus.HEARING_TEST_BOOKED,
      CustomerStatus.HEARING_TEST_BOOKED_PHONE_IN,
      CustomerStatus.AWAITING_TEST_BOOKING,
      CustomerStatus.CLOSED,
    ],
  },
  {
    label: 'Test Cancelled',
    key: CustomerStatus.TEST_CANCELLED,
    transitionTo: [
      CustomerStatus.HEARING_TEST_BOOKED,
      CustomerStatus.HEARING_TEST_BOOKED_PHONE_IN,
      CustomerStatus.AWAITING_TEST_BOOKING,
      CustomerStatus.CLOSED,
    ],
  },
  {
    label: 'Test Cancelled - Rearrange',
    key: CustomerStatus.TEST_CANCELLED_REARRANGE,
    transitionTo: [
      BookingStatus.FIRST_ATTEMPT,
      BookingStatus.SECOND_ATTEMPT,
      BookingStatus.THIRD_ATTEMPT,
      BookingStatus.FOURTH_ATTEMPT,
      BookingStatus.EMAIL_SMS,
      BookingStatus.CALLBACK_LATER,
      CustomerStatus.NOT_INTERESTED,
      CustomerStatus.HEARING_TEST_BOOKED,
      CustomerStatus.HEARING_TEST_BOOKED_PHONE_IN,
    ],
  },
  {
    label: 'Tested - Not Prescribed',
    key: CustomerStatus.TESTED_NOT_PRESCRIBED,
    transitionTo: [
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.FITTING_BOOKED,
      CustomerStatus.CLOSED,
    ],
  },
  {
    label: 'Hearing Test Booked - Phone In',
    key: CustomerStatus.HEARING_TEST_BOOKED_PHONE_IN,
    transitionTo: [
      CustomerStatus.TEST_CANCELLED,
      CustomerStatus.TESTED_NOT_PRESCRIBED,
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.TEST_CANCELLED_REARRANGE,
    ],
  },
  {
    label: 'Hearing Test Booked',
    key: CustomerStatus.HEARING_TEST_BOOKED,
    transitionTo: [
      CustomerStatus.TEST_CANCELLED,
      CustomerStatus.TESTED_NOT_PRESCRIBED,
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.TEST_CANCELLED_REARRANGE,
    ],
  },
  {
    label: 'Awaiting Fitting Booking',
    key: CustomerStatus.AWAITING_FITTING_BOOKING,
    transitionTo: [
      BookingStatus.FIRST_ATTEMPT,
      BookingStatus.SECOND_ATTEMPT,
      BookingStatus.THIRD_ATTEMPT,
      BookingStatus.FOURTH_ATTEMPT,
      BookingStatus.EMAIL_SMS,
      BookingStatus.CALLBACK_LATER,
      CustomerStatus.FITTING_CANCELLED,
      CustomerStatus.FITTING_BOOKED,
    ],
  },
  {
    label: 'Awaiting Fitting Booking (Special Order)',
    key: CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
    transitionTo: [
      BookingStatus.FIRST_ATTEMPT,
      BookingStatus.SECOND_ATTEMPT,
      BookingStatus.THIRD_ATTEMPT,
      BookingStatus.FOURTH_ATTEMPT,
      BookingStatus.EMAIL_SMS,
      BookingStatus.CALLBACK_LATER,
      CustomerStatus.FITTING_CANCELLED,
      CustomerStatus.FITTING_BOOKED,
    ],
  },
  {
    label: 'Fitting Booked',
    key: CustomerStatus.FITTING_BOOKED,
    transitionTo: [
      CustomerStatus.FITTED_AWAITING_DOC_CERT,
      CustomerStatus.FITTED_AWAITING_UPLOAD,
      CustomerStatus.FITTING_COMPLETE,
      CustomerStatus.FITTING_CANCELLED_REARRANGE,
      CustomerStatus.FITTING_CANCELLED,
      CustomerStatus.FITTED_FOLLOW_UP,
    ],
  },
  {
    label: 'Fitting Cancelled - Rearrange',
    key: CustomerStatus.FITTING_CANCELLED_REARRANGE,
    transitionTo: [
      BookingStatus.FIRST_ATTEMPT,
      BookingStatus.SECOND_ATTEMPT,
      BookingStatus.THIRD_ATTEMPT,
      BookingStatus.FOURTH_ATTEMPT,
      BookingStatus.EMAIL_SMS,
      BookingStatus.CALLBACK_LATER,
      CustomerStatus.ORDER_CANCELLED,
      CustomerStatus.FITTING_BOOKED,
    ],
  },
  {
    label: 'Fitting Cancelled',
    key: CustomerStatus.FITTING_CANCELLED,
    transitionTo: [CustomerStatus.ORDER_CANCELLED],
  },
  {
    label: 'Fitted - Awaiting Doc Cert',
    key: CustomerStatus.FITTED_AWAITING_DOC_CERT,
    transitionTo: [CustomerStatus.FITTED_AWAITING_UPLOAD, CustomerStatus.FITTING_COMPLETE],
  },
  {
    label: 'Fitted - Awaiting Upload',
    key: CustomerStatus.FITTED_AWAITING_UPLOAD,
    transitionTo: [CustomerStatus.FITTING_COMPLETE],
  },
  {
    label: 'Fitted - Requires Follow-up',
    key: CustomerStatus.FITTED_FOLLOW_UP,
    transitionTo: [
      BookingStatus.FIRST_ATTEMPT,
      BookingStatus.SECOND_ATTEMPT,
      BookingStatus.THIRD_ATTEMPT,
      BookingStatus.FOURTH_ATTEMPT,
      BookingStatus.EMAIL_SMS,
      BookingStatus.CALLBACK_LATER,
      CustomerStatus.ORDER_CANCELLED,
      CustomerStatus.SERVICE_BOOKED,
    ],
  },
  {
    label: 'Fitting Complete',
    key: CustomerStatus.FITTING_COMPLETE,
    transitionTo: [
      CustomerStatus.ORDER_CANCELLED,
      CustomerStatus.CLOSED,
      CustomerStatus.CLOSED_SPREAD_PAYMENT,
      CustomerStatus.AFTER_CARE_CALL,
    ],
  },
  {
    label: 'AfterCare Call Required',
    key: CustomerStatus.AFTER_CARE_CALL,
    transitionTo: [
      BookingStatus.FIRST_ATTEMPT,
      BookingStatus.SECOND_ATTEMPT,
      BookingStatus.THIRD_ATTEMPT,
      BookingStatus.FOURTH_ATTEMPT,
      BookingStatus.EMAIL_SMS,
      BookingStatus.CALLBACK_LATER,
      CustomerStatus.ORDER_CANCELLED,
      CustomerStatus.CLOSED,
      CustomerStatus.AWAITING_SERVICE_BOOKING,
      CustomerStatus.SERVICE_BOOKED,
    ],
  },
  {
    label: 'Awaiting Service Booking',
    key: CustomerStatus.AWAITING_SERVICE_BOOKING,
    transitionTo: [CustomerStatus.ORDER_CANCELLED, CustomerStatus.CLOSED, CustomerStatus.SERVICE_BOOKED],
  },
  {
    label: 'Service Call Booked',
    key: CustomerStatus.SERVICE_BOOKED,
    transitionTo: [
      CustomerStatus.SERVICE_CANCELLED_REARRANGE,
      CustomerStatus.SERVICE_CANCELLED,
      CustomerStatus.SERVICE_COMPLETE,
    ],
  },
  {
    label: 'Service Call Cancelled - Rearrange',
    key: CustomerStatus.SERVICE_CANCELLED_REARRANGE,
    transitionTo: [CustomerStatus.CLOSED, CustomerStatus.SERVICE_BOOKED],
  },
  {
    label: 'Service Call Cancelled',
    key: CustomerStatus.SERVICE_CANCELLED,
    transitionTo: [CustomerStatus.CLOSED, CustomerStatus.ORDER_CANCELLED],
  },
  {
    label: 'Service Call Complete',
    key: CustomerStatus.SERVICE_COMPLETE,
    transitionTo: [CustomerStatus.CLOSED],
  },
  {
    label: 'Order Cancelled',
    key: CustomerStatus.ORDER_CANCELLED,
    transitionTo: [CustomerStatus.CLOSED],
  },
  {
    label: 'Wax Removal Booked',
    key: CustomerStatus.WAX_REMOVAL_BOOKED,
    transitionTo: [CustomerStatus.CLOSED],
  },
  {
    label: 'Closed',
    key: CustomerStatus.CLOSED,
    transitionTo: [
      CustomerStatus.AWAITING_TEST_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.AWAITING_SERVICE_BOOKING,
      CustomerStatus.ORDER_CANCELLED,
      CustomerStatus.AFTER_CARE_CALL,
    ],
  },
  {
    label: 'Closed - Spread Payment',
    key: CustomerStatus.CLOSED_SPREAD_PAYMENT,
    transitionTo: [
      CustomerStatus.AWAITING_TEST_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.AWAITING_SERVICE_BOOKING,
      CustomerStatus.ORDER_CANCELLED,
      CustomerStatus.AFTER_CARE_CALL,
    ],
  },
];

export const getCustomerStatusFromKey = (key: string) => {
  const status = CustomerStatuses.find((status) => status.key === key);
  return status;
};

export const getCustomerStatusNameFromKey = (key: string) => {
  const status = CustomerStatuses.find((status) => status.key === key);
  return status?.label ?? 'Unknown';
};

export interface ICustomerStatus {
  label: string;
  key: string;
  transitionTo: string[];
}
