import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { useAuthState } from 'core/providers/AuthProvider';
import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { StringIndexable } from 'core/utilities/interface-helpers';

interface IMissedCallDialog {
  customer: IStoredCustomer;
}

interface IMissedCallDialogActionState extends StringIndexable {
  sms: boolean;
  email?: boolean;
}

const MissedCallDialog = ({ customer }: IMissedCallDialog) => {
  const actionState: IMissedCallDialogActionState = {
    sms: false,
  };
  if (customer.emailAddress) {
    actionState.email = false;
  }
  const [loading, setLoading] = useState<IMissedCallDialogActionState>(actionState);
  const [completed, setCompleted] = useState<IMissedCallDialogActionState>(actionState);

  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const { userData } = useAuthState();

  const handleSelection = async (action: 'sms' | 'email') => {
    const { uid, fullName, emailAddress, phoneNumber } = customer;
    const payload = {
      customer: {
        uid,
        fullName,
        emailAddress,
        phoneNumber,
      },
      userFullName: userData?.fullName!,
    };
    setLoading({ ...actionState, [action]: true });
    try {
      switch (action) {
        case 'email':
          await CustomersApiService.sendMissedCallEmail(payload);
          break;
        case 'sms':
          await CustomersApiService.sendMissedCallSMS(payload);
          break;
        default:
          throw new Error('Incorrect action type');
      }
      const newState = { ...completed, [action]: true };
      setCompleted(newState);
      setLoading(actionState);
      showSuccessFlag(
        'Reminder sent',
        `The missed call reminder ${action} was successfully send to ${fullName}`,
        flags
      );
      if (Object.values(newState).every((bool) => bool)) {
        dialog?.closeDialog();
      }
    } catch (error) {
      setLoading(actionState);
      showErrorFlag(
        'Reminder failed to send',
        `Unable to send missed call reminder ${action}, please try again.`,
        flags
      );
    }
  };

  const anyLoading = Object.values(loading).some((bool) => bool);

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'close',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Close',
        type: 'button',
        disabled: anyLoading,
      },
    },
    {
      key: 'sms',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading.sms,
        onClick: () => handleSelection('sms'),
        appearance: 'primary',
        label: completed.sms ? 'SMS sent' : 'Send SMS',
        type: 'button',
        disabled: anyLoading || completed.sms,
      },
    },
  ];

  if (customer.emailAddress) {
    customButtons.splice(2, 0, {
      key: 'email',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading.email,
        onClick: () => handleSelection('email'),
        appearance: 'primary',
        label: completed.email ? 'Email sent' : 'Send email',
        type: 'button',
        disabled: anyLoading || completed.email,
      },
    });
  }

  return (
    <SharedDialogBase
      title={`Send missed call reminder?`}
      textContent='Would you like to send a missed call reminder email or sms to this customer?'
      customButtons={customButtons}
    />
  );
};

export default MissedCallDialog;
