import { useFlags } from '@atlaskit/flag';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { AddCustomerFormFields } from 'core/config/form-fields';
import { AppointmentStatus } from 'core/constants/appointment-status';
import { getAppointmentTypeColor, getAppointmentTypeNameFromKey } from 'core/constants/appointment-type';
import { Permissions } from 'core/constants/permissions';
import { useAuthState } from 'core/providers/AuthProvider';
import { USStringToUKString } from 'core/utilities/date-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { hasPermission } from 'core/utilities/permission-helpers';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import SharedCalendarAppointmentDropdownMenu from 'shared/components/calendar/calendar-appointment-dropdown-menu/calendar-appointment-dropdown-menu';

export interface ICustomerOverviewAppointments {
  customer: IStoredCustomer;
}

const CustomerOverviewAppointments = ({ customer }: ICustomerOverviewAppointments) => {
  const { uid } = customer;
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [loading, setLoading] = useState(true);

  // Hooks
  const flags = useFlags();
  const navigate = useNavigate();
  const { userPermissions } = useAuthState();

  useEffect(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IAppointment>) => {
      const list: IAppointment[] = [];
      querySnapshot.forEach((snap) => {
        list.push(snap.data());
      });
      setAppointments(list);
      setLoading(false);
    };
    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Customer appointments could not be retrieved, please try again.', flags);
    };
    let unsubscribe: Unsubscribe;
    unsubscribe = AppointmentsApiService.subscribeToAppointments(
      handleSnapshot,
      handleSubscriptionError,
      {
        customerId: uid,
      },
      ['date', 'start']
    );
    return () => {
      unsubscribe();
    };
  }, [uid, flags]);

  const createNewAppointment = () => {
    if (AddCustomerFormFields.filter((field) => field.required).some((field) => !customer[field.key])) {
      showErrorFlag(
        'Customer incomplete',
        'You must edit this customer and provide all of the required detail before creating an appointment',
        flags
      );
    } else {
      navigate(`/appointments/new?customer=${uid}`);
    }
  };

  return (
    <div className='bg-white rounded-md shadow-md overflow-hidden'>
      <div className='flex justify-between items-center p-4 border-b'>
        <p className='headline-06'>Customer appointments</p>
        {hasPermission(Permissions.APPOINTMENTS_CREATE, userPermissions) && (
          <SharedButton
            onClick={createNewAppointment}
            type='button'
            appearance='link'
            spacing='none'
            label='Create appointment'
          />
        )}
      </div>
      {loading && (
        <div className='p-4'>
          <div className='h-[24px] w-[75%] bg-gray-100 rounded-md animate-pulse' />
          <div className='mt-1 h-[18px] w-[30%] bg-gray-100 rounded-md animate-pulse' />
        </div>
      )}
      {appointments.map((app) => (
        <div
          key={app.uid}
          className={`border-b last:border-0 flex ${app.status === AppointmentStatus.CANCELLED && 'line-through'}`}
        >
          <div
            className='basis-[6px]'
            style={{
              backgroundColor:
                app.status === AppointmentStatus.CANCELLED ? '#b91c1c' : getAppointmentTypeColor(app.type),
            }}
          />
          <div className='p-4 flex w-full justify-between relative'>
            <div>
              <p className='font-semibold'>{getAppointmentTypeNameFromKey(app.type)} appointment</p>
              <p className='body-02 mt-1'>{app.assignee.fullName}</p>
              <p className='body-02'>
                {USStringToUKString(app.date)} at {app.start}
              </p>
              <p className='body-03 mt-1 text-gray-500'>
                Booked by {app.organiser.fullName} at {dayjs(app.createdAt.toDate()).format('DD/MM/YYYY, HH:mm')}
              </p>
            </div>
            <SharedCalendarAppointmentDropdownMenu appointment={app} subtle={false} customer={customer} alwaysOpen />
          </div>
        </div>
      ))}
      {!loading && appointments.length === 0 && (
        <p className='body-02 text-gray-500 p-4'>No appointments for this customer</p>
      )}
    </div>
  );
};

export default CustomerOverviewAppointments;
