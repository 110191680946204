import { useFlags } from '@atlaskit/flag';
import { IAddCustomerRequestDto, ICustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { AddCustomerFormFields } from 'core/config/form-fields';
import { CustomerStatus } from 'core/constants/customer-status';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import { IAddEditCustomerDialog, IAddEditCustomerFormOutput } from './add-edit-customer-dialog-interface';
import { v4 as uuidv4 } from 'uuid';
import { useLoadScript } from '@react-google-maps/api';
import { GoogleMapsLibraries } from 'core/constants/google-maps-libraries';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import { getCustomerTitleNameFromKey } from 'core/constants/customer-title';
import { getLeadTypeNameFromKey } from 'core/constants/lead-type';
import UpdateCustomerDocumentsDialog from '../update-customer-documents-dialog/update-customer-documents-dialog';
import { useAuthState } from 'core/providers/AuthProvider';
import { IUser } from 'core/api/users/users-api-interface';
import { getObjectChanges } from 'core/utilities/object-helpers';

const AddEditCustomerDialog = ({ uid }: IAddEditCustomerDialog) => {
  // Local State
  const [fetchingData, setFetchingData] = useState(false);
  const [formFields, setFormFields] = useState(AddCustomerFormFields);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: GoogleMapsLibraries,
  });
  const editing = uid !== undefined;
  const [customer, setCustomer] = useState<ICustomer>();

  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const navigate = useNavigate();
  const { userData } = useAuthState();

  const getDefaultValue = (customer: ICustomer, key: string) => {
    switch (key) {
      case 'address':
        return {
          fullAddress: customer.address,
          valid: true,
        };
      case 'title':
        return { value: customer.title, label: getCustomerTitleNameFromKey(customer.title) };
      case 'leadType':
        return { value: customer.leadType, label: getLeadTypeNameFromKey(customer.leadType) };
      default:
        return customer[key];
    }
  };

  useEffect(() => {
    if (editing) {
      const prepare = async () => {
        setFetchingData(true);
        const snap = await CustomersApiService.get(uid);
        setCustomer(snap.data);
        const filledFormFields = AddCustomerFormFields.map((field) => ({
          ...field,
          defaultValue: getDefaultValue(snap.data, field.key),
        }));
        setFormFields(filledFormFields);
        setFetchingData(false);
      };

      prepare();
    }
  }, [editing, uid]);

  // Page specifics
  const addCustomer = async (data: IAddEditCustomerFormOutput) => {
    setFormSubmitting(true);
    const date = new Date();
    const basePayload = {
      ...data,
      address: data.address.fullAddress,
      title: data.title.value,
      leadType: data.leadType.value,
    };
    const uid = customer ? customer.uid : uuidv4();
    const { fullName, uid: userUid } = userData as IUser;
    const author = {
      fullName,
      uid: userUid,
    };
    try {
      if (!editing || !customer) {
        const payload: IAddCustomerRequestDto = {
          ...basePayload,
          uid,
          status: CustomerStatus.CUSTOMER_CREATED,
          lastUpdated: date,
          createdAt: date,
          statusUpdatedAt: date,
          createdBy: author,
          statusUpdatedBy: author,
        };
        const changes = getObjectChanges(payload, {});
        await CustomersApiService.set(payload, changes);
        dialog?.closeDialog();
      } else {
        const changes = getObjectChanges(basePayload, customer);
        await CustomersApiService.update(uid, author, basePayload, changes);
        dialog?.replaceDialog(<UpdateCustomerDocumentsDialog customer={customer} />);
      }
      navigate(`customers/${uid}`);
    } catch (error) {
      showErrorFlag('Customer creation failed', `Unable to create this customer, please try again.`, flags);
      setFormSubmitting(false);
    }
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <>
        {isLoaded ? (
          <SharedForm
            className='p-4 overflow-y-auto'
            onSubmit={addCustomer}
            fields={formFields}
            buttonLabel='Submit'
            loading={formSubmitting}
            cancelButton={cancelButton}
            initialising={fetchingData}
          />
        ) : (
          <div className='p-4'>
            {AddCustomerFormFields.map((field) => (
              <SkeletonElement key={field.key} className='mt-6 first:mt-0' height='36px' width='100%' />
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <SharedDialogBase
      title={editing ? 'Edit customer' : 'Add customer'}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditCustomerDialog;
