import Spinner from '@atlaskit/spinner';
import { Permissions } from 'core/constants/permissions';
import { useAuthState } from 'core/providers/AuthProvider';
import AftercareRouting from 'modules/aftercare/aftercare-routing';
import AppointmentsRouting from 'modules/appointments/appointments-routing';
import BookingRouting from 'modules/booking/booking-routing';
import CustomersRouting from 'modules/customers/customers-routing';
import ForgotPasswordRouting from 'modules/forgot-password/forgot-password-routing';
import LoginRouting from 'modules/login/login-routing';
import OrdersRouting from 'modules/orders/orders-routing';
import ReportsRouting from 'modules/reports/reports-routing';
import SurveyingRouting from 'modules/surveying/surveying-routing';
import UsersRouting from 'modules/users/users-routing';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedRoute from './authenticated-route';
import PublicRoute from './public-route';
import RoleHandler from './role-handler';
import AuditLogRouting from 'modules/audit-log/audit-log-routing';
import { useLoadScript } from '@react-google-maps/api';
import { GoogleMapsLibraries } from 'core/constants/google-maps-libraries';
import FittingRouting from 'modules/fitting/fitting-routing';

const AppRouting = () => {
  const { authChecked, userRoles } = useAuthState();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: GoogleMapsLibraries,
  });

  if (!authChecked || !isLoaded) {
    return (
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <Spinner size={60} />
      </div>
    );
  }

  const getDefaultPath = () => {
    if (userRoles?.includes('surveyor')) {
      return 'surveying';
    } else {
      return 'appointments';
    }
  };

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path='login/*' element={<LoginRouting />} />
        <Route path='forgot-password/*' element={<ForgotPasswordRouting />} />
      </Route>
      <Route element={<AuthenticatedRoute />}>
        <Route element={<RoleHandler requiredPermissions={[Permissions.SURVEYING_CREATE]} />}>
          <Route path='surveying/*' element={<SurveyingRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.USERS_CREATE]} />}>
          <Route path='users/*' element={<UsersRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.CUSTOMERS_GET]} />}>
          <Route path='customers/*' element={<CustomersRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.APPOINTMENTS_GET]} />}>
          <Route path='appointments/*' element={<AppointmentsRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.BOOKING_DASHBOARD]} />}>
          <Route path='booking/*' element={<BookingRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.REPORTING_GET]} />}>
          <Route path='reports/*' element={<ReportsRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.AFTERCARE_DASHBOARD]} />}>
          <Route path='aftercare/*' element={<AftercareRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.ORDER_GET]} />}>
          <Route path='orders/*' element={<OrdersRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.AUDIT_LOG_GET]} />}>
          <Route path='audit-log/*' element={<AuditLogRouting />} />
        </Route>

        <Route element={<RoleHandler requiredPermissions={[Permissions.FITTING_DASHBOARD]} />}>
          <Route path='fitting/*' element={<FittingRouting />} />
        </Route>
      </Route>
      <Route path='' element={<Navigate replace to={getDefaultPath()} />} />
      <Route path='*' element={<Navigate replace to={getDefaultPath()} />} />
    </Routes>
  );
};

export default AppRouting;
