import Button, { LoadingButton } from '@atlaskit/button';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { useFlags } from '@atlaskit/flag';
import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { IOrder } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import OrderDetailsDialog from 'modules/orders/order-details-dialog/order-details-dialog';
import OrderFinanceDetails from 'modules/orders/order-finance-details/order-finance-details';
import OrderProductsBreakdown from 'modules/orders/order-products-breakdown/order-products-breakdown';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { getOrderStatusNameFromKey, OrderStatuses } from 'core/constants/order-status';
import { useAuthState } from 'core/providers/AuthProvider';
import { IUser } from 'core/api/users/users-api-interface';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { hasPermission } from 'core/utilities/permission-helpers';
import { Permissions } from 'core/constants/permissions';
import SelectAppointmentDialog from 'modules/appointments/select-appointment-dialog/select-appointment-dialog';
import SharedButton from 'shared/components/buttons/button';
import { getObjectChanges } from 'core/utilities/object-helpers';

export interface ICustomerOverviewOrders {
  customer: IStoredCustomer;
}

const CustomerOverviewOrders = ({ customer }: ICustomerOverviewOrders) => {
  const { uid } = customer;
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const flags = useFlags();
  const navigate = useNavigate();
  const dialog = useDialog();
  const [ordersUpdating, setOrdersUpdating] = useState<StringIndexable>({});
  const { userData } = useAuthState();
  const { userPermissions } = useAuthState();

  useEffect(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IOrder>) => {
      const list: IOrder[] = [];
      querySnapshot.forEach((snap) => {
        list.push(snap.data());
      });
      setOrders(list);
      setLoading(false);
    };
    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Customer orders could not be retrieved, please try again.', flags);
    };
    let unsubscribe: Unsubscribe;
    unsubscribe = OrdersApiService.subscribeToOrders(
      handleSnapshot,
      handleSubscriptionError,
      {
        customerId: uid,
      },
      ['createdAt']
    );
    return () => {
      unsubscribe();
    };
  }, [uid, flags]);

  const sendEmail = async (uid: string) => {
    showSuccessFlag(
      'Email sent',
      'A email containing a copy of the order confirmation was successfully sent to the customer',
      flags
    );
    try {
      await OrdersApiService.sendOrderConfirmationEmail({ uid });
    } catch (error) {
      showErrorFlag('Email failed to send', 'Could not send the email to the customer, please try again', flags);
    }
  };

  const actionsList = [
    {
      label: 'Send email confirmation',
      key: 'confirmation',
      onClick: (uid: string) => sendEmail(uid),
    },
    {
      label: 'Edit',
      key: 'edit',
      onClick: (uid: string) => navigate(`/orders/edit/${uid}`),
    },
    {
      label: 'View',
      key: 'view',
      onClick: (uid: string) => dialog?.openDialog(<OrderDetailsDialog uid={uid} />),
    },
  ];

  const handleStatusSelection = async (order: IOrder, newStatus: string) => {
    setOrdersUpdating((prevState) => ({ ...prevState, uid: true }));
    const { fullName, uid: userUid } = userData as IUser;
    const payload = {
      status: newStatus,
      updatedAt: new Date(),
      updatedBy: { fullName, uid: userUid },
    };
    await OrdersApiService.update(order.uid, customer.uid, payload, getObjectChanges(payload, order));
    setOrdersUpdating((prevState) => ({ ...prevState, uid: false }));
  };

  return (
    <div className='bg-white rounded-md shadow-md overflow-hidden'>
      <div className='flex justify-between items-center p-4 border-b'>
        <p className='headline-06'>Customer orders</p>
        {hasPermission(Permissions.ORDER_CREATE, userPermissions) && (
          <SharedButton
            onClick={() => dialog?.openDialog(<SelectAppointmentDialog customerUid={customer.uid} />)}
            type='button'
            appearance='link'
            spacing='none'
            label='Create order'
          />
        )}
      </div>
      {loading && (
        <div className='p-4'>
          <div className='h-[24px] w-[75%] bg-gray-100 rounded-md animate-pulse' />
          <div className='mt-1 h-[18px] w-[30%] bg-gray-100 rounded-md animate-pulse' />
        </div>
      )}
      {orders.map((order) => (
        <div key={order.uid} className='p-4 border-b body-02'>
          <div className='flex justify-between items-center mb-3'>
            <p className='font-semibold'>Hearing aid order ({getOrderStatusNameFromKey(order.status)})</p>
            <p className='body-03 text-gray-500 text-right'>
              Created by {order.createdBy.fullName} at {dayjs(order.createdAt.toDate()).format('DD/MM/YYYY, HH:mm')}
            </p>
          </div>
          <OrderProductsBreakdown left={order.left} right={order.right} />
          <div className='flex flex-col-reverse md:items-end md:flex-row justify-between mt-2'>
            <div className='mt-6 md:mt-0 space-x-4'>
              <DropdownMenu
                trigger={({ triggerRef, ...props }) => (
                  <Button {...props} iconAfter={<ChevronDownIcon label='more' />} ref={triggerRef}>
                    Actions
                  </Button>
                )}
              >
                <DropdownItemGroup>
                  {actionsList.map((action) => (
                    <DropdownItem onClick={() => action.onClick(order.uid)} key={action.key}>
                      {action.label}
                    </DropdownItem>
                  ))}
                  {OrderStatuses.length === 0 && <DropdownItem>No options</DropdownItem>}
                </DropdownItemGroup>
              </DropdownMenu>
              <DropdownMenu
                trigger={({ triggerRef, ...props }) => (
                  <LoadingButton
                    isLoading={ordersUpdating[order.uid]}
                    isDisabled={ordersUpdating[order.uid]}
                    {...props}
                    iconAfter={<ChevronDownIcon label='more' />}
                    ref={triggerRef}
                  >
                    Update status
                  </LoadingButton>
                )}
              >
                <DropdownItemGroup>
                  {OrderStatuses.filter((status) => status.key !== order.status).map((status) => (
                    <DropdownItem onClick={() => handleStatusSelection(order, status.key)} key={status.key}>
                      {status.label}
                    </DropdownItem>
                  ))}
                  {OrderStatuses.length === 0 && <DropdownItem>No options</DropdownItem>}
                </DropdownItemGroup>
              </DropdownMenu>
            </div>

            <OrderFinanceDetails
              grossPrice={order.grossPrice}
              discount={order.discount}
              discountedPrice={order.discountedPrice}
              totalPaid={order.totalPaid}
            />
          </div>
        </div>
      ))}
      {!loading && orders.length === 0 && <p className='body-02 text-gray-500 p-4'>No orders for this customer</p>}
    </div>
  );
};

export default CustomerOverviewOrders;
