import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { USStringToUKString } from 'core/utilities/date-helpers';

export const BookingStatus = {
  FIRST_ATTEMPT: 'noAnswer1',
  SECOND_ATTEMPT: 'noAnswer2',
  THIRD_ATTEMPT: 'noAnswer3',
  FOURTH_ATTEMPT: 'noAnswer4',
  EMAIL_SMS: 'noAnswerEmailSMS',
  CALLBACK_LATER: 'callbackLater',
};

export const BookingStatuses: IBookingStatus[] = [
  {
    label: 'No Answer - First attempt',
    key: BookingStatus.FIRST_ATTEMPT,
  },
  {
    label: 'No Answer - Second attempt',
    key: BookingStatus.SECOND_ATTEMPT,
  },
  {
    label: 'No Answer - Third attempt',
    key: BookingStatus.THIRD_ATTEMPT,
  },
  {
    label: 'No Answer - Fourth attempt',
    key: BookingStatus.FOURTH_ATTEMPT,
  },
  {
    label: 'No Answer - Email/SMS sent',
    key: BookingStatus.EMAIL_SMS,
  },
  {
    label: 'Callback later',
    key: BookingStatus.CALLBACK_LATER,
  },
];

export const getBookingStatusFromKey = (key: string) => {
  const status = BookingStatuses.find((status) => status.key === key);
  return status;
};

export const getBookingStatusNameFromKey = (key: string) => {
  const status = BookingStatuses.find((status) => status.key === key);
  return status?.label ?? 'Unknown';
};

export const getBookingStatusValue = (customer: IStoredCustomer, bookingStatusKey: string) => {
  const bookingStatus = getBookingStatusFromKey(bookingStatusKey);
  if (!bookingStatus) {
    return 'Awaiting First Call';
  }

  if (bookingStatus.key === BookingStatus.CALLBACK_LATER) {
    return `Callback on ${USStringToUKString(customer.callback.date)} ${
      customer.callback.time ? 'at ' + customer.callback.time : ''
    }`;
  }

  return bookingStatus.label;
};

export interface IBookingStatus {
  label: string;
  key: string;
}
