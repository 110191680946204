import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { getBookingStatusNameFromKey } from 'core/constants/booking-status';
import { CustomerStatus } from 'core/constants/customer-status';
import { secondsToDateString } from 'core/utilities/date-helpers';
import { CustomersSlice } from 'modules/customers/customers-slice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import PageHeader from 'shared/components/page-header/page-header';
import SharedTable from 'shared/components/table/table';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';

const aftercareTableColumns = [
  { label: 'Customer name', key: 'fullName', templateId: 'text', width: 30 },
  { label: 'Status updated', key: 'lastContact', templateId: 'text', width: 30 },
  { label: 'Contact status', key: 'contactStatus', templateId: 'text', width: 30 },
  { label: '', key: 'action', templateId: 'action', width: 10 },
];

const AftercareDashboard = () => {
  const customers = useSelector(CustomersSlice.selectAll);
  const filteredCustomers = customers.filter((customer) => customer.status === CustomerStatus.AFTER_CARE_CALL);
  const navigate = useNavigate();

  const actionCellTemplate = (customer: IStoredCustomer) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton
          onClick={() => navigate(`/customers/${customer.uid}`)}
          type='button'
          appearance='link'
          label='View'
        />
      </div>
    );
  };

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: actionCellTemplate,
      id: 'action',
    },
  ];

  return (
    <>
      <PageHeader title='Aftercare Dashboard' />
      <div className='mt-4'>
        <SharedTable
          columns={aftercareTableColumns}
          rows={filteredCustomers.map((customer) => ({
            key: customer.uid,
            data: {
              ...customer,
              lastContact: secondsToDateString(customer.statusUpdatedAt),
              contactStatus: customer.bookingStatus
                ? getBookingStatusNameFromKey(customer.bookingStatus)
                : 'Awaiting First Call',
            },
          }))}
          emptyText={'There are no customers awaiting an aftercare call'}
          customTemplates={customTemplates}
        />
      </div>
    </>
  );
};

export default AftercareDashboard;
