const hours = Array.from(Array(24).keys());
const workingHours = hours.filter((hour) => hour >= 9 && hour <= 19);

export const getAppointment15MinuteTimeSlots = () => {
  const timeSlots = workingHours.flatMap((hour, index) => {
    let h = hour.toString();
    if (hour < 10) {
      h = `0${hour}`;
    }
    let hours = [`${h}:00`, `${h}:15`, `${h}:30`, `${h}:45`];
    if (index === workingHours.length - 1) {
      hours = [`${h}:00`, `${h}:15`, `${h}:30`];
    }
    return hours;
  });
  return timeSlots;
};

export const getAppointment60MinuteTimeSlots = () => {
  const timeSlots = workingHours.map((hour) => {
    let h = hour.toString();
    if (hour < 10) {
      h = `0${hour}`;
    }
    return `${h}:00`;
  });
  return timeSlots;
};
