import Checkbox from '@atlaskit/checkbox';
import { DatePicker } from '@atlaskit/datetime-picker';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import { FilterType } from 'core/enums/filter-type';
import { SelectOption, StringIndexable } from 'core/utilities/interface-helpers';
import { useState } from 'react';
import { Filter } from 'react-feather';
import SharedButton from 'shared/components/buttons/button';

export interface ISharedFilterField {
  key: string;
  filterType: FilterType;
  placeholder?: string;
  options?: SelectOption[];
  defaultValue?: SelectOption | SelectOption[] | string;
  label?: string;
  minDate?: string;
  maxDate?: string;
  checkboxes?: ISharedCheckbox[];
}
export interface ISharedCheckbox {
  key: string;
  title: string;
}
export interface ISharedFilters {
  filterInputs: ISharedFilterField[];
  filterValues: StringIndexable;
  changeHandler: Function;
  gridStyles: string;
  startOpen?: boolean;
}

const SharedFilters = ({
  filterInputs,
  filterValues,
  changeHandler,
  gridStyles,
  startOpen = false,
}: ISharedFilters) => {
  const [showFilters, setShowFilters] = useState(startOpen);

  const filterChanged = (key: string, value?: string) => {
    changeHandler(key, value);
  };

  const getDefaultValue = (filter: ISharedFilterField) => {
    const value = filterValues[filter.key];
    if (!value) {
      return null;
    }
    switch (filter.filterType) {
      case FilterType.Select:
        return filter.options?.find((option) => option.value === value);
      default:
        return value;
    }
  };

  const checkboxGroupChanged = (filterKey: string, checkboxKey: string, checked: boolean) => {
    let values = filterValues[filterKey] ? filterValues[filterKey].split(',') : [];
    if (checked) {
      values.push(checkboxKey);
    } else {
      values = values.filter((val: string) => val !== checkboxKey);
    }
    filterChanged(filterKey, values.join(','));
  };

  const getCheckboxDefaultValue = (filter: ISharedFilterField, key: string) => {
    const value = getDefaultValue(filter);
    if (value) {
      return value.split(',').includes(key);
    } else {
      return false;
    }
  };

  const getFilterInput = (filter: ISharedFilterField) => {
    switch (filter.filterType) {
      case FilterType.DatePicker:
        return (
          <DatePicker
            selectProps={{ isSearchable: false }}
            locale={'en-UK'}
            defaultValue={getDefaultValue(filter) ?? ''}
            onChange={(value) => filterChanged(filter.key, value)}
            minDate={filter.minDate}
            maxDate={filter.maxDate}
          />
        );
      case FilterType.CheckboxGroup:
        return (
          <div className='grid grid-cols-2 lg:grid-cols-4 gap-2'>
            {filter.checkboxes?.map((checkbox) => (
              <div className='body-02' key={checkbox.key}>
                <Checkbox
                  label={checkbox.title}
                  onChange={(e) => checkboxGroupChanged(filter.key, checkbox.key, e.target.checked)}
                  isChecked={getCheckboxDefaultValue(filter, checkbox.key)}
                />
              </div>
            ))}
          </div>
        );
      case FilterType.TextField:
        return (
          <TextField
            placeholder={filter.placeholder}
            onChange={(e) => filterChanged(filter.key, e.currentTarget.value)}
            defaultValue={getDefaultValue(filter) ?? ''}
          />
        );
      default:
        return (
          <Select<SelectOption>
            defaultValue={getDefaultValue(filter)}
            options={filter.options}
            placeholder={filter.placeholder}
            onChange={(value) => filterChanged(filter.key, value?.value)}
            isClearable={true}
          />
        );
    }
  };

  return (
    <div className='bg-white rounded-md shadow-md my-4'>
      <div
        className='p-3 flex justify-between items-center cursor-pointer'
        onClick={() => setShowFilters(!showFilters)}
      >
        <div className='flex items-center'>
          <Filter />
          <p className='ml-2 body-02'>Filters</p>
        </div>
        <SharedButton
          onClick={() => setShowFilters(!showFilters)}
          type='button'
          appearance='default'
          label={showFilters ? 'Hide filters' : 'Show filters'}
        />
      </div>
      {showFilters && (
        <div className={`border-t grid p-3 ${gridStyles}`}>
          {filterInputs.map((input) => (
            <div key={input.key} className={input.filterType === FilterType.CheckboxGroup ? 'col-span-4' : ''}>
              {input.label && <p className='label-02 font-semibold mb-1 text-gray-500'>{input.label}</p>}
              {getFilterInput(input)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SharedFilters;
