import { OrderDepositFormFields } from 'core/config/form-fields';
import { ISharedFormField } from 'shared/components/form/form-interface';
import { IOrderFormValues } from '../order-form-interface';
import SignatureCanvas from 'react-signature-canvas';
import { Dispatch, SetStateAction } from 'react';
import { formatToPound } from 'core/utilities/currency-helpers';
interface IOrderFormDeposit {
  formValues: IOrderFormValues;
  getFormFields: (fields: ISharedFormField[], formKey: string) => JSX.Element[];
  setSignature: Dispatch<SetStateAction<SignatureCanvas | null>>;
  showFormError: (fieldKey: string, formKey?: string) => boolean;
  editing: boolean;
  previousSignature?: string;
}

const OrderFormDeposit = ({
  formValues,
  getFormFields,
  setSignature,
  showFormError,
  editing,
  previousSignature,
}: IOrderFormDeposit) => {
  const showSigError = showFormError('dispenserSignature');

  return (
    <div className='bg-white w-full rounded-md shadow-md h-fit mt-4'>
      <div className='p-4 border-b headline-06'>Deposit</div>
      <div className='p-4'>
        {getFormFields(OrderDepositFormFields, 'financial')}
        <div className='body-02 mt-6'>
          I acknowledge receipt of {formatToPound(formValues.financial.deposit ?? 0)}. I have completed the
          demonstration in accordance with company regulations
        </div>
        <p className='label-02 font-semibold mb-1 text-gray-500 mt-4'>Audiologist signature:</p>
        {editing && previousSignature ? (
          <img src={previousSignature} alt='signature' className='max-h-[180px] py-6' />
        ) : (
          <SignatureCanvas
            penColor='black'
            canvasProps={{
              className: `border w-full h-[200px] ${showSigError && 'border-red-500 border-2'}`,
            }}
            ref={(ref) => {
              setSignature(ref);
            }}
          />
        )}
        {showSigError && <p className='mt-1 label-02 text-red-600'>A signature is required</p>}
      </div>
    </div>
  );
};

export default OrderFormDeposit;
