import { getBookingStatusValue } from 'core/constants/booking-status';
import { CustomerStatus, getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import { getLeadTypeNameFromKey } from 'core/constants/lead-type';
import { useDialog } from 'core/providers/DialogProvider';
import { secondsToDateString } from 'core/utilities/date-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import AddEditCustomerDialog from 'modules/customers/add-customer/add-edit-customer-dialog';
import { ICustomerOverviewChild } from '../customer-overview-interface';
import DeleteCustomersDialog from 'modules/customers/delete-customers-dialog/delete-customers-dialog';
import { hasRole } from 'core/utilities/permission-helpers';
import { useAuthState } from 'core/providers/AuthProvider';
import { createAddressString } from 'core/utilities/address-string-builder';

const CustomerOverviewDetails = ({ customer }: ICustomerOverviewChild) => {
  const { userRoles } = useAuthState();
  const dialog = useDialog();
  const customerDetails = [
    {
      label: 'Customer name',
      value: customer.fullName,
      key: 'name',
    },
    {
      label: 'Status',
      value: getCustomerStatusNameFromKey(customer.status),
      key: 'status',
    },
    {
      label: 'Status last updated',
      value: secondsToDateString(customer.statusUpdatedAt),
      key: 'statusUpdatedAt',
    },
    {
      label: 'Status updated by',
      value: customer.statusUpdatedBy?.fullName ?? 'Unknown',
      key: 'statusUpdatedBy',
    },
    {
      label: 'Address',
      value: customer.address
        ? createAddressString([...customer.address.formatted_address, customer.address.postcode])
        : 'Unknown',
      key: 'address',
    },
    {
      label: 'Phone number',
      value: isNotNullOrEmpty(customer.phoneNumber) ? customer.phoneNumber : 'Not provided',
      key: 'phoneNumber',
    },
    {
      label: 'Other number',
      value: isNotNullOrEmpty(customer.mobileNumber) ? customer.mobileNumber : 'Not provided',
      key: 'mobileNumber',
    },
    {
      label: 'Email address',
      value: isNotNullOrEmpty(customer.emailAddress) ? customer.emailAddress : 'Not provided',
      key: 'emailAddress',
    },
    {
      label: 'Date of birth',
      value: isNotNullOrEmpty(customer.dob) ? customer.dob : 'Not provided',
      key: 'dob',
    },
    {
      label: 'Age',
      value: isNotNullOrEmpty(customer.age) ? customer.age : 'Not provided',
      key: 'age',
    },
    {
      label: 'Lead type',
      value: getLeadTypeNameFromKey(customer.leadType),
      key: 'leadType',
    },
  ];

  if (
    [
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.AWAITING_TEST_BOOKING,
      CustomerStatus.TEST_CANCELLED_REARRANGE,
      CustomerStatus.FITTING_CANCELLED_REARRANGE,
      CustomerStatus.FITTED_FOLLOW_UP,
      CustomerStatus.AFTER_CARE_CALL,
    ].some((status) => status === customer.status)
  ) {
    customerDetails.splice(2, 0, {
      label: 'Booking status',
      value: getBookingStatusValue(customer, customer.bookingStatus),
      key: 'bookingStatus',
    });
  }

  return (
    <div className='flex-grow-0 flex-shrink-0 basis-[280px] xl:basis-[360px] bg-white shadow-md rounded-md h-fit'>
      <div className='border-b p-4 flex justify-between items-center'>
        <p className='headline-06'>Details</p>
        <div className='flex space-x-5'>
          {hasRole(['superAdmin'], userRoles) && (
            <p
              className='body-02 text-red-500 hover:underline cursor-pointer'
              onClick={() => {
                dialog?.openDialog(<DeleteCustomersDialog customers={[customer.uid]} goBack />);
              }}
            >
              Delete
            </p>
          )}
          <p
            className='body-02 text-blue-700 hover:underline cursor-pointer'
            onClick={() => {
              dialog?.openDialog(<AddEditCustomerDialog uid={customer.uid} />);
            }}
          >
            Edit
          </p>
        </div>
      </div>
      <div className='p-4'>
        {customerDetails.map((section) => (
          <div key={section.key} className='mt-4 first:mt-0'>
            <p className='body-02 text-gray-500'>{section.label}</p>
            <p className='body-01 break-all'>{section.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerOverviewDetails;
