export const AidType = {
  ESSENTIAL: 'essential',
  ADVANCED: 'advanced',
  PREMIUM: 'premium',
  PREMIUM_PRO: 'premiumPro',
};

export const AidTypes = [
  {
    label: 'Essential',
    value: AidType.ESSENTIAL,
  },
  {
    label: 'Advanced',
    value: AidType.ADVANCED,
  },
  {
    label: 'Premium',
    value: AidType.PREMIUM,
  },
  {
    label: 'Premium Pro',
    value: AidType.PREMIUM_PRO,
  },
];

export const getAidTypeFromKey = (key: string) => {
  return AidTypes.find((type) => type.value === key);
};

export const getAidTypeNameFromKey = (key: string) => {
  const type = AidTypes.find((type) => type.value === key);
  return type?.label ?? 'Unknown';
};
