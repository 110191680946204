import { IAudiogram } from 'core/api/audiogram/audiogram-api-interface';
import AudiogramApiService from 'core/api/audiogram/audiogram-api.service';
import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { getAudiometerNameFromKey } from 'core/constants/audiometer-options';
import { getMeatusNameFromKey } from 'core/constants/meatus';
import { getPinnaNameFromKey } from 'core/constants/pinna';
import { getTMNameFromKey } from 'core/constants/tm';
import { getYesNoNameFromKey } from 'core/constants/yes-no';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import dayjs from 'dayjs';
import AudiogramDiagram from 'modules/audiogram/audiogram-form/audiogram-diagram/audiogram-diagram';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Clock } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';

interface ICustomerOverviewAudiogram {
  customer: IStoredCustomer;
}

const testDetailsFields = [
  {
    key: 'dispenser',
    label: 'Audiologist',
  },
  {
    key: 'hearingTestDate',
    label: 'Test date',
  },
  {
    key: 'significantOther',
    label: 'Significant other',
  },
  {
    key: 'significantOtherRelation',
    label: 'Significant other relation',
  },
  {
    key: 'durationOfLoss',
    label: 'Duration of loss',
  },
  {
    key: 'occupation',
    label: 'Occupation',
  },
  {
    key: 'familyDeafness',
    label: 'Family deafness',
  },
  {
    key: 'doctor',
    label: 'Doctor/Surgery',
  },
  {
    key: 'audiometer',
    label: 'Audiometer',
  },
];

const earHealthTableColumns = [
  { label: 'Pinna', key: 'pinna', dataTransform: getPinnaNameFromKey },
  { label: 'Meatus', key: 'meatus', dataTransform: getMeatusNameFromKey },
  { label: 'TM', key: 'tm', dataTransform: getTMNameFromKey },
  { label: 'Discharge', key: 'discharge', dataTransform: getYesNoNameFromKey },
  { label: 'Earache', key: 'earache', dataTransform: getYesNoNameFromKey },
  { label: 'Tinnitus', key: 'tinnitus', dataTransform: getYesNoNameFromKey },
  { label: 'Conductive', key: 'conductive', dataTransform: getYesNoNameFromKey },
];

const ears = [
  { label: 'Left', key: 'left' },
  { label: 'Right', key: 'right' },
];

const hearingLossCausesTableColumns = [
  { label: 'Noise', key: 'noise' },
  { label: 'Unilateral', key: 'unilateral' },
  { label: 'Asymmetrical', key: 'asymmetrical' },
  { label: 'Sudden onset', key: 'suddenOnset' },
  { label: 'Sudden worsening', key: 'suddenWorsening' },
  { label: 'Fluctuating', key: 'fluctuating' },
  { label: 'Vertigo', key: 'vertigo' },
];

const CustomerOverviewAudiogram = ({ customer }: ICustomerOverviewAudiogram) => {
  const navigate = useNavigate();
  const [initializing, setInitializing] = useState(true);
  const [audiogram, setAudiogram] = useState<IAudiogram>();

  const initialize = useCallback(async (uid: string) => {
    try {
      const snap = await AudiogramApiService.getSnapshot(uid);
      if (snap.exists()) {
        const data = snap.data();
        setAudiogram(data);
      }
      setInitializing(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    initialize(customer.uid);
  }, [customer.uid, initialize]);

  const getFieldValue = (audiogram: IAudiogram, key: string) => {
    switch (key) {
      case 'audiometer':
        return getAudiometerNameFromKey(audiogram.audiometer);
      case 'hearingTestDate':
        return dayjs(audiogram.hearingTestDate).format('DD/MM/YYYY');
      default:
        return audiogram[key] as string;
    }
  };

  const getAudiogramSections = (audiogram: IAudiogram) => [
    {
      key: 'details',
      label: 'Hearing test details',
      content: (
        <div className='grid grid-cols-4 w-full gap-4'>
          {testDetailsFields.map(({ key, label }) => (
            <div key={key}>
              <p className='body-02 text-gray-500'>{label}</p>
              <p className='body-01 break-all'>{getFieldValue(audiogram, key)}</p>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: 'earHealth',
      label: 'Ear health',
      content: (
        <div className='border body-02'>
          <div className='grid grid-cols-8 bg-gray-50 font-semibold'>
            <p className='border-r p-2'></p>
            {earHealthTableColumns.map((col) => (
              <p className='p-2 flex items-center' key={col.key}>
                {col.label}
              </p>
            ))}
          </div>
          {ears.map((side) => {
            const earHealth = side.key === 'left' ? audiogram.leftEarHealth : audiogram.rightEarHealth;
            return (
              <div className='grid grid-cols-8 border-t' key={side.key}>
                <p className='border-r p-2 bg-gray-50 font-semibold'>{side.label}</p>
                {earHealthTableColumns.map((col) => (
                  <p className='p-2' key={col.key}>
                    {col.dataTransform(earHealth[col.key])}
                  </p>
                ))}
              </div>
            );
          })}
        </div>
      ),
    },
    {
      key: 'hearingLossCauses',
      label: 'Hearing loss causes',
      content: (
        <div className='border body-02'>
          <div className='grid grid-cols-7'>
            {hearingLossCausesTableColumns.map((col) => (
              <p className='p-2 flex items-center bg-gray-50 font-semibold' key={col.key}>
                {col.label}
              </p>
            ))}
            {hearingLossCausesTableColumns.map((col) => (
              <p className='p-2 flex items-center border-t' key={col.key}>
                {getYesNoNameFromKey(audiogram.hearingLossCauses[col.key])}
              </p>
            ))}
          </div>
        </div>
      ),
    },
    {
      key: 'notes',
      label: 'Additional notes',
      content: (
        <p className='body-01 break-all'>
          {isNotNullOrEmpty(audiogram.notes) ? audiogram.notes : 'No notes were added'}
        </p>
      ),
    },
  ];

  const getAudiogramValues = (audiogram: IAudiogram) => {
    if (audiogram.isV2) {
      const { leftv2, rightv2, bil } = audiogram.audiogramValues;
      return {
        left: leftv2!,
        right: rightv2!,
        bil: bil!,
      };
    } else {
      const { left, right, boneConduction } = audiogram.audiogramValues;
      return {
        left: {
          ac: left!,
        },
        right: {
          ac: right!,
        },
        bil: {
          acMask: boneConduction!,
        },
      };
    }
  };

  return (
    <div className='bg-white rounded-md shadow-md max-w-[868px]'>
      <div className='p-4 border-b flex justify-between items-center'>
        <p className='headline-06'>Customer audiogram</p>
        <SharedButton
          onClick={() => navigate(`/customers/${customer.uid}/audiogram`)}
          type='button'
          appearance='link'
          spacing='none'
          label={audiogram ? 'Edit audiogram' : 'Create audiogram'}
        />
      </div>

      {initializing && (
        <div className='p-4'>
          <div className='h-[400px] w-full bg-gray-100 rounded-md animate-pulse' />
          <div className='mt-1 h-[18px] w-[30%] bg-gray-100 rounded-md animate-pulse' />
        </div>
      )}

      {audiogram && (
        <div className='p-4'>
          <AudiogramDiagram
            currentEar=''
            currentMarker=''
            onValueChange={() => {}}
            audiogramValues={getAudiogramValues(audiogram)}
            setCurrentMarker={() => {}}
            setCurrentEar={() => {}}
            showFormError={() => false}
            dimensions={{
              height: '500px',
              width: '100%',
            }}
            id='audiogram-diagram'
            viewOnly
          />
          {getAudiogramSections(audiogram).map((section) => (
            <Fragment key={section.key}>
              <div className='h-px bg-gray-100 w-full my-4' />
              <p className='headline-07 mb-2'>{section.label}</p>
              {section.content}
            </Fragment>
          ))}

          <div className='h-px bg-gray-100 w-full my-4' />
          <div className='mt-4 flex items-center text-gray-500'>
            <Clock size={16} />
            <p className='ml-1 body-03'>{`Updated: ${dayjs(audiogram.updatedAt.toDate()).format(
              'DD/MM/YYYY, HH:mm'
            )} by ${audiogram.updatedBy.fullName}`}</p>
          </div>
        </div>
      )}

      {!initializing && !audiogram && (
        <p className='body-02 text-gray-500 p-4'>No audiogram completed for this customer</p>
      )}
    </div>
  );
};

export default CustomerOverviewAudiogram;
