import { useFlags } from '@atlaskit/flag';
import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import DocumentApiService from 'core/api/documents/documents-api.service';
import { UploadDocumentDialogFormFields } from 'core/config/form-fields';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import { v4 as uuid } from 'uuid';

interface IUploadDocumentDialog {
  customer: IStoredCustomer;
}

const UploadDocumentDialog = ({ customer }: IUploadDocumentDialog) => {
  const { uid } = customer;
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { userData } = useAuthState();

  // Hooks
  const dialog = useDialog();
  const flags = useFlags();

  const cancelButton: IButton = {
    onClick: () => {
      dialog?.closeDialog();
    },
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const upload = async (data: any) => {
    setFormSubmitting(true);
    try {
      const { fileCapture, type } = data;
      const docUid = uuid();
      const payload = {
        uid: docUid,
        customerUid: uid,
        createdAt: new Date(),
        path: `${type.value}/${docUid}_${fileCapture.name}`,
        type: type.value,
        author: userData?.fullName,
      };
      await DocumentApiService.upload(fileCapture.dataUrl, fileCapture.type, payload.path);
      await DocumentApiService.set(payload);
      dialog?.closeDialog();
      showSuccessFlag('Document uploaded', 'The document was successfully uploaded', flags);
    } catch (error) {
      showErrorFlag('Document upload failed', `Unable to upload this document, please try again.`, flags);
      setFormSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4'
        onSubmit={upload}
        fields={UploadDocumentDialogFormFields}
        buttonLabel='Upload'
        cancelButton={cancelButton}
        loading={formSubmitting}
      />
    );
  };

  return <SharedDialogBase title='Document upload' customContentTemplate={customContent()} showButtons={false} />;
};

export default UploadDocumentDialog;
