export const Roles: IRole[] = [
  {
    label: 'Super Admin',
    key: 'superAdmin',
  },
  {
    label: 'Admin',
    key: 'admin',
  },
  {
    label: 'Hearing Specialist',
    key: 'hearingSpecialist',
  },
  {
    label: 'Audiologist',
    key: 'audiologist',
  },
  {
    label: 'Hearing Aid Dispenser',
    key: 'hearingAidDispenser',
  },
  {
    label: 'Surveyor',
    key: 'surveyor',
  },
  {
    label: 'Booker',
    key: 'booker',
  },
  {
    label: 'Specialised Booker',
    key: 'specialisedBooker',
  },
  {
    label: 'Unique Booker',
    key: 'uniqueBooker',
  },
  {
    label: 'Aftercare Agent',
    key: 'aftercareAgent',
  },
  {
    label: 'Wax Specialist',
    key: 'waxSpecialist',
  },
];

export const getRoleFromKey = (key: string) => {
  const role = Roles.find((role) => role.key === key);
  return role;
};

export const getRoleNameFromKey = (key: string) => {
  const role = Roles.find((role) => role.key === key);
  return role?.label ?? 'Unknown';
};

export interface IRouteRoles {
  requiredPermissions?: string[];
  includeLayout?: boolean;
}

export interface IRole {
  label: string;
  key: string;
}
