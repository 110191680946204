import { useFlags } from '@atlaskit/flag';
import { AuditLogActionType, IAuditLog, IAuditLogChange } from 'core/api/audit-log/audit-log-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { showErrorFlag } from 'core/utilities/flags-helper';
import dayjs from 'dayjs';
import { CustomersSlice } from 'modules/customers/customers-slice';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import SharedTable from 'shared/components/table/table';
import { ISharedTableCustomCellTemplate, ISharedTableRow } from 'shared/components/table/table-interface';
import Lozenge, { ThemeAppearance } from '@atlaskit/lozenge';
import { QuerySnapshot } from 'firebase/firestore';
import SharedPopupCell from 'shared/components/table/table-body/popup-cell/popup-cell';

interface IAuditLogRow {
  uid: string;
  userName: string;
  actionType: AuditLogActionType;
  resource: {
    type: string;
    uid: string;
    meta?: string;
  };
  timestamp: string;
  customer?: {
    fullName: string;
    uid: string;
  };
  changes?: IAuditLogChange[];
}

interface IAuditLogTable {
  logsSnap?: QuerySnapshot<IAuditLog>;
  showCustomer?: boolean;
  dataLoading: boolean;
  pagination?: {
    nextPageFunction: Function;
    limit: number;
  };
}

const AuditLogTable = ({ logsSnap, showCustomer = false, dataLoading, pagination }: IAuditLogTable) => {
  const [auditLogs, setAuditLogs] = useState<ISharedTableRow<IAuditLogRow>[]>([]);
  const flags = useFlags();
  const storedCustomers = useSelector(CustomersSlice.selectAll);
  const navigate = useNavigate();
  const [processingRawData, setProcessingRawData] = useState(true);

  const getCustomer = useCallback(
    async (uid: string | null) => {
      if (!uid) {
        return;
      }

      const customer = storedCustomers.find((customer) => customer.uid === uid);
      if (customer) {
        return {
          fullName: customer.fullName,
          uid: customer.uid,
        };
      }

      try {
        const result = await CustomersApiService.get(uid);
        return {
          fullName: result.data.fullName,
          uid: result.data.uid,
        };
      } catch (error) {
        return {
          fullName: 'Deleted',
          uid: '',
        };
      }
    },
    [storedCustomers]
  );

  const getAuditLogs = useCallback(
    async (logsSnap: QuerySnapshot<IAuditLog>) => {
      try {
        const promises = logsSnap.docs.map(async (auditLog) => {
          const { createdAt, author, customerUid, resourceType, resourceUid, resourceMeta, ...rest } = auditLog.data();
          return {
            key: auditLog.id,
            data: {
              ...rest,
              userName: author.fullName,
              customer: showCustomer ? await getCustomer(customerUid) : undefined,
              timestamp: dayjs(createdAt.toDate()).format('DD/MM/YYYY, HH:mm'),
              resource: { type: resourceType, uid: resourceUid, meta: resourceMeta },
            },
          };
        });
        const logs = await Promise.all(promises);
        setAuditLogs(logs);
        setProcessingRawData(false);
      } catch (error) {
        showErrorFlag('An error occurred', 'The audit logs could not be retrieved, please try again.', flags);
      }
    },
    [flags, getCustomer, showCustomer]
  );

  useEffect(() => {
    if (logsSnap) {
      getAuditLogs(logsSnap);
    }
  }, [getAuditLogs, logsSnap]);

  const AuditLogTableColumns = [
    { label: 'User', key: 'userName', templateId: 'text', width: 20 },
    { label: 'Resource', key: 'resource', templateId: 'resource', width: 20 },
    { label: 'Action', key: 'actionType', templateId: 'actionType', width: 20 },
    { label: 'Timestamp', key: 'timestamp', templateId: 'text', width: 20 },
  ];

  if (showCustomer) {
    AuditLogTableColumns.splice(3, 0, { label: 'Customer', key: 'customer', templateId: 'customer', width: 20 });
  }

  const customerCell = ({ customer }: IAuditLogRow) => {
    if (customer) {
      return customer.uid.trim() === '' ? (
        <p className='text-gray-300'>Deleted</p>
      ) : (
        <SharedButton
          onClick={() => navigate(`/customers/${customer.uid}`)}
          type='button'
          appearance='link'
          label={customer.fullName}
          spacing='none'
        />
      );
    }
    return <p>N/A</p>;
  };

  const resourceCell = ({ resource }: IAuditLogRow) => {
    let label = resource.meta ? `${resource.type} - ${resource.meta}` : resource.type;
    return <Lozenge isBold>{label}</Lozenge>;
  };

  const getChangeDetail = (change: IAuditLogChange, actionType: AuditLogActionType) => {
    let key: string = change.key;
    let valueTransform: Function = (value: any) => {
      const type = typeof value;
      if (type === 'boolean') {
        return value ? 'Yes' : 'No';
      }

      return value;
    };
    if (change.key.toLowerCase().includes('uid')) {
      return;
    }

    if (change.key.includes('address') && [change.original, change.updated].every((val) => !val)) {
      return;
    }

    switch (change.key) {
      case 'date':
      case 'callback.date':
      case 'fittingDate':
      case 'hearingTestDate':
        valueTransform = (value: string) => (value ? dayjs(value).format('DD/MM/YYYY') : '');
        break;
      case 'assignee.fullName':
        key = 'Assignee';
        break;
      case 'organiser.fullName':
        key = 'Organiser';
        break;
      case 'statusUpdatedBy.fullName':
      case 'updatedBy.fullName':
        key = 'Updater';
        break;
      case 'createdBy.fullName':
        key = 'Creator';
        break;
      case 'dispenser.fullName':
        key = 'Audiologist';
        break;
      case 'booker.fullName':
        key = 'Booker';
        break;
      case 'surveyor.fullName':
        key = 'Surveyor';
        break;
      case 'assignee.roles':
      case 'customerSignaturePath':
      case 'dispenserSignaturePath':
      case 'audiogramPath':
        return;
    }

    return (
      <div
        key={change.key}
        className={`text-sm border-b grid ${actionType === 'update' ? 'grid-cols-3' : 'grid-cols-2'}`}
      >
        <p className='py-1 px-2 capitalize break-words'>{key}</p>
        {actionType === 'update' && (
          <p className='bg-red-100 py-1 px-2 break-words'>{valueTransform(change.original)}</p>
        )}
        <p className='bg-green-100 py-1 px-2 break-words'>{valueTransform(change.updated)}</p>
      </div>
    );
  };

  const actionTypeCell = ({ actionType, changes }: IAuditLogRow) => {
    let appearance: ThemeAppearance = 'success';
    switch (actionType) {
      case 'update':
        appearance = 'inprogress';
        break;
      case 'delete':
        appearance = 'removed';
    }
    return (
      <SharedPopupCell
        previewContent={
          <Lozenge isBold appearance={appearance}>
            {actionType}
          </Lozenge>
        }
        popupContent={
          changes && (
            <div className='w-[600px]'>
              <p className='body-03 p-3 border-b font-semibold'>{actionType === 'create' ? 'Details' : 'Changes'}</p>
              <div className='max-h-[500px] overflow-y-auto'>
                {changes.reduce((filtered: ReactNode[], change) => {
                  const detail = getChangeDetail(change, actionType);
                  if (detail) {
                    filtered.push(detail);
                  }
                  return filtered;
                }, [])}
              </div>
            </div>
          )
        }
      />
    );
  };

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: customerCell,
      id: 'customer',
    },
    {
      template: actionTypeCell,
      id: 'actionType',
    },
    {
      template: resourceCell,
      id: 'resource',
    },
  ];

  return (
    <SharedTable
      columns={AuditLogTableColumns}
      rows={auditLogs}
      customTemplates={customTemplates}
      isLoading={dataLoading || processingRawData}
      pagination={pagination}
    />
  );
};

export default AuditLogTable;
