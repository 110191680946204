export const AidStyle = {
  BTE: 'bte',
  RIC: 'ric',
  CIC: 'cic',
  ITC: 'itc',
  ITE_HALF_SHELL: 'iteHalfShell',
  ITE_FULL_SHELL: 'iteFullShell',
};

export const AidStyles = [
  {
    label: 'BTE',
    value: AidStyle.BTE,
  },
  {
    label: 'RIC',
    value: AidStyle.RIC,
  },
  {
    label: 'CIC',
    value: AidStyle.CIC,
  },
  {
    label: 'ITC',
    value: AidStyle.ITC,
  },
  {
    label: 'ITE 1/2 Shell',
    value: AidStyle.ITE_HALF_SHELL,
  },
  {
    label: 'ITE Full Shell',
    value: AidStyle.ITE_FULL_SHELL,
  },
];

export const getAidStyleFromKey = (key: string) => {
  return AidStyles.find((style) => style.value === key);
};

export const getAidStyleNameFromKey = (key: string) => {
  const style = AidStyles.find((style) => style.value === key);
  return style?.label ?? 'Unknown';
};
