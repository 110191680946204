import SharedForm from 'shared/components/form/form';
import { LoginFormFields } from 'core/config/form-fields';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'core/config/firebase';
import { useState } from 'react';
import { ILoginFormOutput } from './login-interface';
import { assertIsError } from 'core/utilities/asset-is-error';
import { ErrorMappingHelper } from 'core/utilities/error-mapping-helper';
import AuthPageWrapper from 'shared/components/layout/auth-page-wrapper';
import { IButton } from 'shared/components/buttons/button-interface';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFormSubmit = async (data: ILoginFormOutput) => {
    try {
      setLoading(true);
      setError(undefined);
      await signInWithEmailAndPassword(auth, data.emailAddress, data.password);
    } catch (error: any) {
      const assertedError = assertIsError(error);
      setError(ErrorMappingHelper(assertedError, 'Incorrect email or password'));
      setLoading(false);
    }
  };

  const forgotPasswordButton: IButton = {
    onClick: () => navigate('/forgot-password'),
    label: 'Forgot password',
    appearance: 'subtle',
    type: 'button',
  };

  return (
    <AuthPageWrapper>
      <p className='headline-04 mb-8'>
        Welcome to the
        <br />
        <span className='font-semibold'>Pride Hearing CRM Portal</span>
      </p>
      <p className='body-02 mb-8'>Please sign-in to your account</p>
      <SharedForm
        onSubmit={onFormSubmit}
        fields={LoginFormFields}
        buttonLabel='Log in'
        loading={loading}
        formErrorMessage={error}
        cancelButton={forgotPasswordButton}
      />
    </AuthPageWrapper>
  );
};

export default Login;
