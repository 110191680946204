import { Navigate, Route, Routes } from 'react-router-dom';
import AftercareDashboard from './aftercare-dashboard/aftercare-dashboard';

const AftercareRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AftercareDashboard />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AftercareRouting;
