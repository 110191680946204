import { useFlags } from '@atlaskit/flag';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import { IOrder, IOrderProductDetail } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { CustomerStatuses, getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import { AidLengths } from 'core/constants/hearing-aid-lengths';
import { AidPowerOptions, getAidPowerOptionNameFromKey } from 'core/constants/hearing-aid-power-options';
import { AidReceiverGains } from 'core/constants/hearing-aid-receiver-gains';
import { AidStyles } from 'core/constants/hearing-aid-styles';
import { AidTypes } from 'core/constants/hearing-aid-types';
import { getOrderStatusNameFromKey, OrderStatuses } from 'core/constants/order-status';
import { FilterType } from 'core/enums/filter-type';
import { useDialog } from 'core/providers/DialogProvider';
import { createAddressString } from 'core/utilities/address-string-builder';
import { formatToPound } from 'core/utilities/currency-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { PageElement, StringIndexable } from 'core/utilities/interface-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import SelectAppointmentDialog from 'modules/appointments/select-appointment-dialog/select-appointment-dialog';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { ParamKeyValuePair, useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import SharedFilters, { ISharedFilterField } from 'shared/components/filters/filters';
import PageHeader from 'shared/components/page-header/page-header';
import SharedTable from 'shared/components/table/table';
import SharedPopupCell from 'shared/components/table/table-body/popup-cell/popup-cell';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import OrderDetailsDialog from '../order-details-dialog/order-details-dialog';
import OrderFinanceDetails from '../order-finance-details/order-finance-details';
import OrderProductsBreakdown from '../order-products-breakdown/order-products-breakdown';
import OrderListCountBreakdownDialog from './order-list-count-breakdown-dialog/order-list-count-breakdown-dialog';

const UsersTableColumns = [
  { label: 'Date', key: 'createdAt', templateId: 'text', width: 10 },
  { label: 'Customer', key: 'customer', templateId: 'customer', width: 10 },
  { label: 'Customer location', key: 'customerLocation', templateId: 'text', width: 15 },
  { label: 'Customer status', key: 'customerStatus', templateId: 'text', width: 10 },
  { label: 'Order status', key: 'orderStatus', templateId: 'text', width: 10 },
  { label: 'Product details', key: 'productInfo', templateId: 'productInfo', width: 15 },
  { label: 'Sale information', key: 'saleInfo', templateId: 'saleInfo', width: 25 },
  { label: '', key: 'action', templateId: 'action', width: 5 },
];

export interface IOrderListTableRowData {
  createdAt: string;
  customer: ICustomer;
  customerLocation: string;
  customerStatus: string;
  orderStatus: string;
  productInformation: { left?: IOrderProductDetail; right?: IOrderProductDetail; power: string };
  saleInformation: {
    grossPrice: number;
    discount: number;
    discountedPrice: number;
    deposit: number;
    totalPaid: number;
  };
}

const OrdersList = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const flags = useFlags();
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState<StringIndexable>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = useDialog();

  useEffect(() => {
    setFilterValues({});
    searchParams.forEach((value, key) => {
      setFilterValues((prevState) => {
        return { ...prevState, [key]: value };
      });
    });
  }, [searchParams]);

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Existing appointments could not be retrieved, please try again.', flags);
    },
    [flags]
  );

  const createOrdersSubscription = useCallback(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IOrder>) => {
      const list: IOrder[] = [];
      querySnapshot.forEach((snap) => {
        list.push(snap.data());
      });
      setOrders(list);
    };
    return OrdersApiService.subscribeToOrders(handleSnapshot, handleSubscriptionError, {}, ['createdAt']);
  }, [handleSubscriptionError]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    unsubscribe = createOrdersSubscription();
    return () => {
      unsubscribe();
    };
  }, [createOrdersSubscription]);

  const getCustomerLocation = (customer: ICustomer) => {
    if (customer.address) {
      const { town_or_city, postcode } = customer.address;
      return createAddressString([town_or_city, postcode]);
    }

    return customer.mvfCounty ?? 'Check notes';
  };

  const customerCell = ({ customer }: IOrderListTableRowData) => {
    return (
      <SharedButton
        onClick={() => navigate(`/customers/${customer.uid}`)}
        type='button'
        appearance='link'
        label={customer.fullName}
        spacing='none'
      />
    );
  };

  const productInformationCell = ({ productInformation }: IOrderListTableRowData) => {
    const { left, right, power } = productInformation;
    const numberOfAids = [left, right].filter((ear) => ear).length;
    return (
      <SharedPopupCell
        hover
        previewContent={
          <p className='mr-1'>
            {numberOfAids} aids ({getAidPowerOptionNameFromKey(power)})
          </p>
        }
        popupContent={<OrderProductsBreakdown left={left} right={right} />}
      />
    );
  };

  const saleInformationCell = ({ saleInformation }: IOrderListTableRowData) => {
    const { grossPrice, discountedPrice, totalPaid, discount } = saleInformation;
    const columns = [
      { label: 'Goods/Services:', value: formatToPound(grossPrice), key: 'grossPrice' },
      { label: 'Balance:', value: formatToPound(discountedPrice - totalPaid), key: 'balance' },
    ];
    return (
      <SharedPopupCell
        hover
        previewContent={
          <div className='xl:grid xl:grid-cols-2 w-full'>
            {columns.map((column) => (
              <Fragment key={column.key}>
                <p>{column.label}</p>
                <p className='xl:text-right mb-2 xl:mb-0'>{column.value}</p>
              </Fragment>
            ))}
          </div>
        }
        popupContent={
          <div className='p-2 body-02'>
            <OrderFinanceDetails
              grossPrice={grossPrice}
              discount={discount}
              discountedPrice={discountedPrice}
              totalPaid={totalPaid}
            />
          </div>
        }
      />
    );
  };

  const actionCellTemplate = (order: IOrder) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton
          onClick={() => dialog?.openDialog(<OrderDetailsDialog uid={order.uid} />)}
          type='button'
          appearance='link'
          label='View'
        />
      </div>
    );
  };

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: customerCell,
      id: 'customer',
    },
    {
      template: productInformationCell,
      id: 'productInfo',
    },
    {
      template: saleInformationCell,
      id: 'saleInfo',
    },
    {
      template: actionCellTemplate,
      id: 'action',
    },
  ];

  const handleFilterChange = (key: string, value: string) => {
    if (!value) {
      searchParams.delete(key);
      setSearchParams(searchParams, { replace: true });
    } else {
      const newValues: ParamKeyValuePair[] = [];
      searchParams.forEach((currentVal, currentKey) => {
        if (currentKey !== key) {
          newValues.push([currentKey, currentVal]);
        }
      });
      newValues.push([key, value]);
      setSearchParams(newValues);
    }
  };

  const filters: ISharedFilterField[] = [
    {
      key: 'orderDateFrom',
      filterType: FilterType.DatePicker,
      label: 'Order date from',
      maxDate: filterValues.orderDateTo ?? '',
    },
    {
      key: 'orderDateTo',
      filterType: FilterType.DatePicker,
      label: 'Order date to',
      minDate: filterValues.orderDateFrom ?? '',
    },
    {
      key: 'status',
      filterType: FilterType.Select,
      label: 'Order status',
      options: OrderStatuses.map((status) => ({ value: status.key, label: status.label })),
    },
    {
      key: 'customerStatus',
      filterType: FilterType.Select,
      options: CustomerStatuses.map((status) => ({ value: status.key, label: status.label })),
      label: 'Customer status',
    },
    {
      key: 'type',
      filterType: FilterType.Select,
      options: AidTypes,
      label: 'Aid type',
    },
    {
      key: 'power',
      filterType: FilterType.Select,
      options: AidPowerOptions,
      label: 'Aid power',
    },
    {
      key: 'style',
      filterType: FilterType.Select,
      options: AidStyles,
      label: 'Aid style',
    },
    {
      key: 'length',
      filterType: FilterType.Select,
      options: AidLengths,
      label: 'Receiver length',
    },
    {
      key: 'gain',
      filterType: FilterType.Select,
      options: AidReceiverGains,
      label: 'Gain size',
    },
    {
      key: 'deposit',
      filterType: FilterType.TextField,
      label: 'Deposit',
    },
  ];

  const filteredOrders = orders.filter((order) =>
    Object.keys(filterValues)
      .filter((key) => isNotNullOrEmpty(filterValues[key]))
      .every((key) => {
        const value = filterValues[key];
        if (!value) {
          return true;
        }
        switch (key) {
          case 'orderDateFrom':
            return dayjs(value).toDate() < order.createdAt.toDate();
          case 'orderDateTo':
            return dayjs(value).set('hour', 23).set('minute', 59).toDate() > order.createdAt.toDate();
          case 'customerStatus':
            return value === order.customer.status;
          case 'deposit':
            return Number(value) === order.deposit;
          case 'type':
          case 'style':
          case 'gain':
          case 'length':
            return [order.left, order.right].some((aid) => (aid ? aid[key] === value : false));
          default:
            return value === order[key];
        }
      })
  );

  const counts = [
    {
      key: 'total',
      label: 'Total orders:',
      value: filteredOrders.length,
    },
  ];

  const headerActions: PageElement[] = [
    {
      key: 'createOrder',
      element: (
        <SharedButton
          onClick={() => dialog?.openDialog(<SelectAppointmentDialog />)}
          type='button'
          appearance='primary'
          label='Create Order'
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Orders' actions={headerActions} />
      <div className='grid grid-cols-1 md:flex border-b body-02'>
        {counts.map((count) => (
          <button
            key={count.key}
            className='text-left py-4 md:mr-4 last:mr-0 pr-4 last:pr-0 border-0 border-b md:border-b-0 last:border-0 md:border-r'
            onClick={() =>
              dialog?.openDialog(<OrderListCountBreakdownDialog filters={filterValues} orders={filteredOrders} />)
            }
          >
            {count.label} <span className='font-semibold'>{count.value}</span>
          </button>
        ))}
      </div>
      <SharedFilters
        filterInputs={filters}
        filterValues={filterValues}
        changeHandler={handleFilterChange}
        gridStyles='grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'
      />
      <SharedTable<IOrderListTableRowData>
        columns={UsersTableColumns}
        customTemplates={customTemplates}
        rows={filteredOrders.map(
          ({
            uid,
            customer,
            status,
            left,
            right,
            power,
            grossPrice,
            discount,
            deposit,
            discountedPrice,
            totalPaid,
            createdAt,
          }) => ({
            key: uid,
            data: {
              uid,
              createdAt: dayjs(createdAt.toDate()).format('DD/MM/YYYY'),
              customer: customer,
              customerLocation: getCustomerLocation(customer),
              customerStatus: getCustomerStatusNameFromKey(customer.status),
              orderStatus: getOrderStatusNameFromKey(status),
              productInformation: {
                left,
                right,
                power,
              },
              saleInformation: {
                grossPrice,
                discount,
                deposit,
                discountedPrice,
                totalPaid,
              },
            },
          })
        )}
      />
    </>
  );
};

export default OrdersList;
