import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { AppointmentType } from 'core/constants/appointment-type';
import { useDialog } from 'core/providers/DialogProvider';
import { QuerySnapshot } from 'firebase/firestore';
import React from 'react';
import { useCallback } from 'react';
import SharedButton from 'shared/components/buttons/button';
import SharedCustomerListDialog, {
  ISharedCustomerListDialogRow,
} from 'shared/components/dialogs/customer-list-dialog/customer-list-dialog';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import { AppointmentStatus } from 'core/constants/appointment-status';
import { getRandomColor } from 'core/utilities/color-helpers';
import { getLeadTypeNameFromKey } from 'core/constants/lead-type';
import { CustomerStatus } from 'core/constants/customer-status';
import { IOrder } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { AidPowerOption } from 'core/constants/hearing-aid-power-options';
import { AidType } from 'core/constants/hearing-aid-types';
import { formatToPound } from 'core/utilities/currency-helpers';

interface IHearingTestLeadTypeOutcomeReportCell {
  label: string;
  count: number;
  prescribed: number;
  tnp: number;
  upgraded: number;
  value: number;
  totalCustomers: ISharedCustomerListDialogRow[];
  prescribedCustomers: ISharedCustomerListDialogRow[];
  tnpCustomers: ISharedCustomerListDialogRow[];
  upgradedCustomers: ISharedCustomerListDialogRow[];
}

interface IDialogButton {
  rows: ISharedCustomerListDialogRow[];
  label: string;
}

interface IAppointmentOrderData {
  upgraded: number;
  value: number;
  appointment: IAppointment;
}

const OpenDialogButton = ({ rows, label }: IDialogButton) => {
  const dialog = useDialog();
  return (
    <div>
      <SharedButton
        onClick={() => dialog?.openDialog(<SharedCustomerListDialog rows={rows} />)}
        type='button'
        spacing='none'
        appearance='link'
        label={label}
      />
    </div>
  );
};

const ReportBreakdownCustomCell = (data: IHearingTestLeadTypeOutcomeReportCell) => {
  const {
    label,
    count,
    prescribed,
    tnp,
    totalCustomers,
    tnpCustomers,
    prescribedCustomers,
    upgraded,
    upgradedCustomers,
    value,
  } = data;
  return (
    <div className='grid grid-cols-10 md:grid-cols-3 md:gap-x-4 gap-y-1'>
      <p className='col-span-1 md:col-span-1 body-01 mb-4'></p>
      <div className='col-span-9 md:col-span-2 grid grid-cols-5 body-02 mb-4 text-right gap-x-1'>
        <p>Total</p>
        <p>Sold</p>
        <p>TNP</p>
        <p>Upgraded</p>
        <p>Value</p>
      </div>
      <p className='col-span-1 md:col-span-1'>{label}</p>
      <div className='col-span-9 md:col-span-2 grid grid-cols-5 body-02 gap-x-1 text-right'>
        <OpenDialogButton rows={totalCustomers} label={count.toString()} />
        <OpenDialogButton
          rows={prescribedCustomers}
          label={`${prescribed} (${((prescribed / count) * 100).toFixed(1)}%)`}
        />
        <OpenDialogButton rows={tnpCustomers} label={`${tnp} (${((tnp / count) * 100).toFixed(1)}%)`} />
        <OpenDialogButton rows={upgradedCustomers} label={`${upgraded} (${((upgraded / count) * 100).toFixed(1)}%)`} />
        <p>{formatToPound(value)}</p>
      </div>
    </div>
  );
};

const HearingTestLeadTypeOutcomeReport = () => {
  const processRows = useCallback(async (querySnapshot: QuerySnapshot<IAppointment>) => {
    const rows: IReportBaseTableRows<IHearingTestLeadTypeOutcomeReportCell>[] = [];
    const promises = querySnapshot.docs.map((doc) => {
      return new Promise<IAppointmentOrderData>(async (resolve, reject) => {
        const { prescribed, uid } = doc.data();
        let order: IOrder | null = null;
        let upgraded: number = 0;
        let value: number = 0;
        if (prescribed) {
          const orders = await OrdersApiService.listAll({ linkedAppointmentUid: uid });
          order = !orders.empty ? orders.docs[0].data() : null;
        }

        if (order) {
          const { left, right, grossPrice, power } = order;
          const aids = [left, right].filter((ear) => ear);
          upgraded = aids.some((aid) => power === AidPowerOption.RECHARGEABLE || aid?.type !== AidType.ESSENTIAL)
            ? 1
            : 0;
          value = grossPrice;
        }
        resolve({ appointment: doc.data(), upgraded, value });
      });
    });

    const appointmentOrderData = await Promise.all(promises);
    appointmentOrderData.forEach((data) => {
      const { appointment, upgraded, value } = data;
      const { customer, prescribed, status } = appointment;
      const customerListRow = { customer };
      if (status === AppointmentStatus.CANCELLED) {
        return;
      }

      const rowIndex = rows.findIndex((row) => row.key === customer.leadType);
      if (rowIndex !== -1) {
        rows[rowIndex].data.count += 1;
        rows[rowIndex].data.totalCustomers.push(customerListRow);
        if (prescribed) {
          rows[rowIndex].data.prescribed += 1;
          rows[rowIndex].data.prescribedCustomers.push(customerListRow);
        } else if (customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED) {
          rows[rowIndex].data.tnp += 1;
          rows[rowIndex].data.tnpCustomers.push(customerListRow);
        }
        if (upgraded > 0) {
          rows[rowIndex].data.upgraded += upgraded;
          rows[rowIndex].data.upgradedCustomers.push(customerListRow);
        }
        rows[rowIndex].data.value += value;
      } else {
        rows.push({
          key: customer.leadType,
          color: getRandomColor(),
          data: {
            label: getLeadTypeNameFromKey(customer.leadType),
            prescribed: prescribed ? 1 : 0,
            tnp: customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED ? 1 : 0,
            count: 1,
            upgraded: upgraded,
            value,
            totalCustomers: [customerListRow],
            prescribedCustomers: prescribed ? [customerListRow] : [],
            tnpCustomers: customer.status === CustomerStatus.TESTED_NOT_PRESCRIBED ? [customerListRow] : [],
            upgradedCustomers: upgraded > 0 ? [customerListRow] : [],
          },
        });
      }
    });

    return rows;
  }, []);

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'leadType',
    },
  ];

  const tableColumns = [{ label: 'Lead type', key: 'leadType', templateId: 'leadType', width: 100 }];

  return (
    <>
      <PageHeader title='Lead type outcome report' showBack />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeAppointmentDate: before,
          afterAppointmentDate: after,
          types: [AppointmentType.HOME_TEST, AppointmentType.SHOP_TEST],
        })}
        subscriber={AppointmentsApiService.subscribeToAppointments}
        totalLabel={'Total number of bookings:'}
        emptyText={'No appointments created during the selected time frame'}
        customTemplates={customTemplates}
        queryOrdering={['fullDate']}
      />
    </>
  );
};

export default HearingTestLeadTypeOutcomeReport;
