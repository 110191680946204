export const DocumentType = {
  SURVEY_LETTER: 'surveyLetter',
  ENVELOPE: 'envelope',
  HEARING_TEST_APPOINTMENT_LETTER: 'hearingTestAppointmentLetter',
  MEDICAL_CERTIFICATE: 'medicalCertificate',
  SALES_ORDER: 'salesOrder',
  AUDIOGRAM: 'audiogram',
  ME2_FORM: 'me2Form',
  CAMPAIGN_SHEET: 'campaignSheet',
  FITTING_NOTE: 'fittingNote',
  CARD_RECEIPT: 'cardReceipt',
  GENERAL: 'general',
};

export const DocumentTypes: IDocumentType[] = [
  {
    label: 'Survey Letter',
    key: DocumentType.SURVEY_LETTER,
    uploadable: false,
  },
  {
    label: 'Envelope',
    key: DocumentType.ENVELOPE,
    uploadable: false,
  },
  {
    label: 'Hearing Test Appointment Letter',
    key: DocumentType.HEARING_TEST_APPOINTMENT_LETTER,
    uploadable: false,
  },
  {
    label: 'Medical Certificate',
    key: DocumentType.MEDICAL_CERTIFICATE,
    uploadable: true,
  },
  {
    label: 'Sales Order',
    key: DocumentType.SALES_ORDER,
    uploadable: true,
  },
  {
    label: 'Audiogram',
    key: DocumentType.AUDIOGRAM,
    uploadable: true,
  },
  {
    label: 'ME2 Form',
    key: DocumentType.ME2_FORM,
    uploadable: true,
  },
  {
    label: 'Campaign Sheet',
    key: DocumentType.CAMPAIGN_SHEET,
    uploadable: true,
  },
  {
    label: 'Fitting Note/Receipt',
    key: DocumentType.FITTING_NOTE,
    uploadable: true,
  },
  {
    label: 'Card Receipt',
    key: DocumentType.CARD_RECEIPT,
    uploadable: true,
  },
  {
    label: 'General',
    key: DocumentType.GENERAL,
    uploadable: true,
  },
];

export const getDocumentTypeFromKey = (key: string) => {
  const type = DocumentTypes.find((type) => type.key === key);
  return type;
};

export const getDocumentTypeNameFromKey = (key: string) => {
  const type = DocumentTypes.find((type) => type.key === key);
  return type?.label ?? 'Unknown';
};

export interface IDocumentType {
  label: string;
  key: string;
  uploadable: boolean;
}
