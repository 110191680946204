import { IOrder } from 'core/api/orders/orders-api-interface';
import { getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import { getAidLengthNameFromKey } from 'core/constants/hearing-aid-lengths';
import { getAidPowerOptionNameFromKey } from 'core/constants/hearing-aid-power-options';
import { getAidReceiverGainNameFromKey } from 'core/constants/hearing-aid-receiver-gains';
import { getAidStyleNameFromKey } from 'core/constants/hearing-aid-styles';
import { getAidTypeNameFromKey } from 'core/constants/hearing-aid-types';
import { getOrderStatusNameFromKey } from 'core/constants/order-status';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';

interface IOrderListCountBreakdownDialog {
  orders: IOrder[];
  filters: StringIndexable;
}

interface IBreakdownGroup {
  breakdown: IBreakdown[];
  key: string;
  labelFunc: Function;
  title: string;
  type: 'aid' | 'order' | 'customer';
  custom?: boolean;
}

interface IBreakdown {
  count: number;
  key: string;
}

const OrderListCountBreakdownDialog = ({ orders, filters }: IOrderListCountBreakdownDialog) => {
  const groups: IBreakdownGroup[] = [
    {
      breakdown: [],
      key: 'power',
      labelFunc: (key: string) => getAidPowerOptionNameFromKey(key),
      title: 'Aid power',
      type: 'order',
    },
    {
      breakdown: [],
      key: 'type',
      labelFunc: (key: string) => getAidTypeNameFromKey(key),
      title: 'Aid type',
      type: 'aid',
    },
    {
      breakdown: [],
      key: 'style',
      labelFunc: (key: string) => getAidStyleNameFromKey(key),
      title: 'Aid style',
      type: 'aid',
    },
    {
      breakdown: [],
      key: 'length',
      labelFunc: (key: string) => getAidLengthNameFromKey(key),
      title: 'Receiver length',
      type: 'aid',
    },
    {
      breakdown: [],
      key: 'gain',
      labelFunc: (key: string) => getAidReceiverGainNameFromKey(key),
      title: 'Gain size',
      type: 'aid',
    },
    {
      breakdown: [],
      key: 'status',
      labelFunc: (key: string) => getOrderStatusNameFromKey(key),
      title: 'Order status',
      type: 'order',
    },
    {
      breakdown: [],
      key: 'status',
      labelFunc: (key: string) => getCustomerStatusNameFromKey(key),
      title: 'Customer status',
      type: 'customer',
    },
  ];

  const processBreakdown = (index: number, groupIndex: number, key: string) => {
    if (index === -1) {
      groups[groupIndex].breakdown.push({
        key,
        count: 1,
      });
    } else {
      groups[groupIndex].breakdown[index].count += 1;
    }
  };

  orders.forEach((order) => {
    groups.forEach((group, index) => {
      let itemIndex: number;
      let itemKey: string;
      switch (group.type) {
        case 'aid':
          [order.left, order.right]
            .filter((ear) =>
              Object.keys(filters)
                .filter((key) => isNotNullOrEmpty(filters[key]))
                .every((key) => {
                  const value = filters[key];
                  if (!value) {
                    return true;
                  }
                  switch (key) {
                    case 'type':
                    case 'style':
                    case 'gain':
                    case 'length':
                      return ear ? ear[key] === value : false;
                    default:
                      return true;
                  }
                })
            )
            .forEach((ear) => {
              if (ear) {
                itemKey = ear[group.key];
                itemIndex = groups[index].breakdown.findIndex((b) => b.key === itemKey);
                processBreakdown(itemIndex, index, itemKey);
              }
            });
          break;
        case 'customer':
          itemKey = order.customer[group.key];
          itemIndex = groups[index].breakdown.findIndex((b) => b.key === order.customer[group.key]);
          processBreakdown(itemIndex, index, itemKey);
          break;
        default:
          itemKey = order[group.key];
          itemIndex = groups[index].breakdown.findIndex((b) => b.key === itemKey);
          processBreakdown(itemIndex, index, itemKey);
      }
    });
  });

  const customContent = () => {
    return (
      <div className='overflow-y-auto'>
        <div className='grid grid-cols-1 md:grid-cols-2'>
          {groups.map((group, index) => (
            <div key={group.title} className='first:border-b md:first:border-b-0 border-r border-dashed even:border-r-0'>
              {index !== 0 && index !== 1 && <div className='h-0 border-t border-dashed' />}
              <div className='p-4'>
                <p className='headline-06'>{group.title}</p>
                {group.breakdown
                  .sort((a, b) => b.count - a.count)
                  .map((bd) => (
                    <div
                      key={bd.key}
                      className='body-02 break-all whitespace-pre-wrap mt-1 flex justify-between hover:bg-gray-50'
                    >
                      <p>{group.labelFunc(bd.key)}:</p>
                      <p>{bd.count}</p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <SharedDialogBase
      title='Orders breakdown'
      customContentTemplate={
        orders.length > 0 ? customContent() : <div className='p-4'>No orders matching your filters</div>
      }
    />
  );
};

export default OrderListCountBreakdownDialog;
