import { useFlags } from '@atlaskit/flag';
import { useEffect, useState } from 'react';
import { ICustomerOverviewSurvey } from './customer-overview-survey-interface';
import { ISurvey } from 'core/api/surveys/surveys-api-interface';
import SurveysApiService from 'core/api/surveys/surveys-api.service';
import dayjs from 'dayjs';
import { Clock } from 'react-feather';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import { CustomerStatus } from 'core/constants/customer-status';

const CustomerOverviewSurvey = ({ customer }: ICustomerOverviewSurvey) => {
  const [surveys, setSurveys] = useState<ISurvey[]>([]);
  const [loading, setLoading] = useState(true);
  const [sendingSurveyEmail, setSendingSurveyEmail] = useState(false);

  const flags = useFlags();

  useEffect(() => {
    const prepare = async () => {
      setLoading(true);
      const { data } = await SurveysApiService.getSurveysForCustomer(customer.uid);
      setSurveys(data);
      setLoading(false);
    };

    prepare();
  }, [customer.uid, flags]);

  const questions = [
    {
      key: 'hearingProblems',
      content: 'Does anybody in your home have hearing problems?',
    },
    {
      key: 'length',
      content: 'How long have you had hearing trouble?',
    },
    {
      key: 'affects',
      content: "How would you say you're hearing effects you?",
    },
    {
      key: 'lastTest',
      content: 'When were you last tested?',
    },
    {
      key: 'additionalNotes',
      content: 'Any additional notes',
    },
  ];

  const sendSurveyEmail = async () => {
    setSendingSurveyEmail(true);
    try {
      const { uid, fullName, emailAddress } = customer;
      await CustomersApiService.sendSurveyEmail({
        uid,
        fullName,
        emailAddress,
      });
      setSendingSurveyEmail(false);
      showSuccessFlag('Email sent', `The survey confirmation email was successfully send to ${fullName}`, flags);
    } catch (error) {
      setSendingSurveyEmail(false);
      showErrorFlag('Email failed to send', `Unable to send survey confirmation email, please try again.`, flags);
    }
  };

  return (
    <div className='bg-white rounded-md shadow-md'>
      <div className='p-4 border-b flex justify-between items-center'>
        <p className='headline-06'>Customer survey</p>
        {customer.emailAddress &&
          surveys.length > 0 &&
          [CustomerStatus.AWAITING_TEST_BOOKING, CustomerStatus.SURVEY_LETTER_SENT, CustomerStatus.SURVEY_CREATED].some(
            (status) => status === customer.status
          ) && (
            <SharedLoadingButton
              onClick={sendSurveyEmail}
              type='button'
              appearance='link'
              spacing='none'
              label='Send survey email'
              isLoading={sendingSurveyEmail}
            />
          )}
      </div>

      {loading && (
        <div className='p-4'>
          <div className='h-[24px] w-[75%] bg-gray-100 rounded-md animate-pulse' />
          <div className='mt-1 h-[18px] w-[30%] bg-gray-100 rounded-md animate-pulse' />
        </div>
      )}

      {surveys.map((survey) => (
        <div key={survey.uid} className='p-4 border-b last:border-0'>
          {questions.map((question) => (
            <div key={question.key} className='mt-4 first:mt-0'>
              <p className='body-02 font-semibold'>{question.content}</p>
              {survey[question.key] !== '' ? (
                <p className='body-02 whitespace-pre-wrap'>{survey[question.key]}</p>
              ) : (
                <p className='body-02 whitespace-pre-wrap text-gray-400'>Nothing added</p>
              )}
            </div>
          ))}
          <div className='mt-4 flex items-center text-gray-500'>
            <Clock size={16} />
            <p className='ml-1 body-03'>{`Created: ${dayjs(survey.createdAt.toDate()).format('DD/MM/YYYY, HH:mm')} by ${
              survey.surveyor.fullName
            }`}</p>
          </div>
        </div>
      ))}
      {!loading && surveys.length === 0 && (
        <p className='body-02 text-gray-500 p-4'>No survey completed for this customer</p>
      )}
    </div>
  );
};

export default CustomerOverviewSurvey;
