import TextArea from '@atlaskit/textarea';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import AudiogramApiService from 'core/api/audiogram/audiogram-api.service';
import { ICustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { PageElement, SelectOption, StringIndexable } from 'core/utilities/interface-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ISharedFormField } from 'shared/components/form/form-interface';
import PageHeader from 'shared/components/page-header/page-header';
import PageHeaderSkeleton from 'shared/components/page-header/page-header-skeleton';
import AudiogramDiagram from './audiogram-diagram/audiogram-diagram';
import HearingTestDetails from './hearing-test-details/hearing-test-details';
import dayjs from 'dayjs';
import { DatePicker } from '@atlaskit/datetime-picker';
import EarHealth from './ear-health/ear-health';
import { AudiometerOptions, getAudiometerFromKey } from 'core/constants/audiometer-options';
import HearingLossCauses from './hearing-loss-causes/hearing-loss-causes';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useFlags } from '@atlaskit/flag';
import { EarHealthFormFields, HearingLossCausesFormFields } from 'core/config/form-fields';
import SharedButton from 'shared/components/buttons/button';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import {
  IAddAudiogramRequestDto,
  IAddAudiogramValue,
  IAudiogram,
  IAudiogramEarHealth,
  IAudiogramEarValues,
  IAudiogramHearingLossCauses,
  IAudiogramSectionValue,
  IUpdateAudiogramRequestDto,
} from 'core/api/audiogram/audiogram-api-interface';
import { useAuthState } from 'core/providers/AuthProvider';
import * as htmlToImage from 'html-to-image';
import DocumentApiService from 'core/api/documents/documents-api.service';
import AudiogramFormSuccessDialog from './audiogram-form-success-dialog/audiogram-form-success-dialog';
import { useDialog } from 'core/providers/DialogProvider';
import SegmentedSelect from 'shared/components/form/segmented-select/segmented-select';
import { IAudiogramFormError, IAudiogramDiagramValues, IAudiogramMainFormValues } from './audiogram-form-interface';
import { getObjectChanges } from 'core/utilities/object-helpers';

const AudiogramForm = () => {
  const [initializing, setInitializing] = useState(true);
  const [editing, setEditing] = useState(false);
  const { uid } = useParams();
  const [customer, setCustomer] = useState<ICustomer>();
  const [currentEar, setCurrentEar] = useState<string>('left');
  const [currentMarker, setCurrentMarker] = useState<string>('ac');
  const [audiogramValues, setAudiogramValues] = useState<IAudiogramDiagramValues>({
    left: {},
    right: {},
    bil: {},
  });
  const [formValues, setFormValues] = useState<IAudiogramMainFormValues>({
    details: {
      testDate: dayjs(new Date()).format('YYYY-MM-DD'),
    },
    leftEarHealth: {},
    rightEarHealth: {},
    hearingLossCauses: {},
  });
  const [formLoading, setFormLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<IAudiogramFormError[]>([]);
  const flags = useFlags();
  const navigate = useNavigate();
  const { userData } = useAuthState();
  const dialog = useDialog();
  const [existingData, setExistingData] = useState<IAudiogram>();

  const mainFormSections = [
    {
      key: 'leftEarHealth',
      fields: EarHealthFormFields,
      required: true,
    },
    {
      key: 'rightEarHealth',
      fields: EarHealthFormFields,
      required: true,
    },
    {
      key: 'hearingLossCauses',
      fields: HearingLossCausesFormFields,
      required: true,
    },
    {
      key: 'details',
      fields: [
        {
          key: 'dispenser',
          required: true,
        },
        {
          key: 'testDate',
          required: true,
        },
        {
          key: 'significantOther',
          required: true,
        },
        {
          key: 'significantOtherRelation',
          required: true,
        },
        {
          key: 'durationOfLoss',
          required: true,
        },
        {
          key: 'occupation',
          required: true,
        },
        {
          key: 'familyDeafness',
          required: true,
        },
        {
          key: 'doctor',
          required: true,
        },
        {
          key: 'audiometer',
          required: true,
        },
      ],
      required: true,
    },
  ];
  const audiogramXValues = [125, 250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000, 8000];

  const initialize = useCallback(async (uid: string) => {
    try {
      const [snap, { data }] = await Promise.all([AudiogramApiService.getSnapshot(uid), CustomersApiService.get(uid)]);
      if (snap.exists()) {
        setExistingData(snap.data());
        setEditing(true);
        const {
          hearingTestDate,
          significantOther,
          significantOtherRelation,
          durationOfLoss,
          occupation,
          familyDeafness,
          doctor,
          dispenser,
          audiogramValues,
          leftEarHealth,
          rightEarHealth,
          hearingLossCauses,
          audiometer,
          notes,
          fittingDate,
          isV2,
        } = snap.data();

        setFormValues({
          details: {
            testDate: hearingTestDate,
            significantOther,
            significantOtherRelation,
            durationOfLoss,
            occupation,
            familyDeafness,
            doctor,
            dispenser: dispenser,
            audiometer: getAudiometerFromKey(audiometer),
            notes,
            fittingDate,
          },
          leftEarHealth,
          rightEarHealth,
          hearingLossCauses,
        });
        if (isV2) {
          const { leftv2, rightv2, bil } = audiogramValues;
          setAudiogramValues({
            left: leftv2!,
            right: rightv2!,
            bil: bil!,
          });
        } else {
          const { left, right, boneConduction } = audiogramValues;
          setAudiogramValues({
            left: {
              ac: left!,
            },
            right: {
              ac: right!,
            },
            bil: {
              acMask: boneConduction!,
            },
          });
        }
      }
      setCustomer(data);
      setInitializing(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (uid) {
      initialize(uid);
    }
  }, [initialize, uid]);

  useEffect(() => {
    if (currentEar === 'bil') {
      setCurrentMarker('ac');
    }
  }, [currentEar]);

  if (initializing) {
    return <PageHeaderSkeleton subtitle={false} actions={[]} />;
  }

  const onAudiogramValueChange = (x: number, y: number) => {
    const newSectionValue = { ...audiogramValues[currentEar][currentMarker] };
    const current = newSectionValue[x];
    if (current === y) {
      delete newSectionValue[x];
    } else {
      newSectionValue[x] = y;
    }
    setAudiogramValues((prevState) => ({
      ...prevState,
      [currentEar]: { ...prevState[currentEar], [currentMarker]: newSectionValue },
    }));
  };

  const showFormError = (fieldKey: string, formKey?: string) =>
    formErrors.some((err) => {
      return err.fieldKey === fieldKey && (formKey ? err.groupKey === formKey : true);
    });

  const getFormInput = (field: ISharedFormField, formKey: string) => {
    switch (field.control) {
      case ControlType.Select:
        return (
          <Select<SelectOption>
            options={field.options}
            isSearchable={false}
            onChange={(value) => valueChanged(formKey, field.key, value)}
            value={formValues[formKey][field.key] ?? null}
            validationState={showFormError(field.key, formKey) ? 'error' : 'default'}
            isDisabled={formLoading}
          />
        );
      case ControlType.TextArea:
        return (
          <TextArea
            minimumRows={8}
            onChange={(e) => valueChanged(formKey, field.key, e.currentTarget.value)}
            value={formValues[formKey][field.key] ?? ''}
            isDisabled={formLoading}
          />
        );
      case ControlType.DatePicker:
        return (
          <DatePicker
            onChange={(value) => valueChanged(formKey, field.key, value)}
            value={formValues[formKey][field.key] ?? ''}
            locale={'en-UK'}
          />
        );
      case ControlType.SegmentedSelect:
        return (
          <SegmentedSelect
            value={formValues[formKey][field.key]}
            options={field.options}
            onChange={(value) => valueChanged(formKey, field.key, value)}
            isInvalid={showFormError(field.key, formKey)}
            isDisabled={formLoading}
          />
        );
      default:
        return (
          <TextField
            type={field.type}
            onChange={(e) => {
              let value: string | number = e.currentTarget.value;
              if (field.type === InputType.Number && isNotNullOrEmpty(value)) {
                value = +value;
              }
              valueChanged(formKey, field.key, value);
            }}
            value={formValues[formKey][field.key] ?? ''}
            isInvalid={showFormError(field.key, formKey)}
            isDisabled={formLoading}
          />
        );
    }
  };

  const getFormFields = (fields: ISharedFormField[], formKey: string) =>
    fields
      .filter((field) => !field.hidden)
      .map((field) => (
        <div key={field.key} className='mt-3 first:mt-0'>
          {field.label && <p className='label-02 font-semibold mb-1 text-gray-500'>{field.label}</p>}
          {getFormInput(field, formKey)}
          {showFormError(field.key, formKey) && <p className='mt-1 label-02 text-red-600'>This field is required</p>}
        </div>
      ));

  const valueChanged = (formKey: string, fieldKey: string, value: string | boolean | SelectOption | null | number) => {
    setFormErrors([]);
    setFormValues((prevState) => {
      return {
        ...prevState,
        [formKey]: {
          ...prevState[formKey],
          [fieldKey]: value,
        },
      };
    });
  };

  const copyEarHealthDetail = (key: string) => {
    const dataToCopy = formValues[key];
    const keyToUpdate = key === 'leftEarHealth' ? 'rightEarHealth' : 'leftEarHealth';
    setFormErrors([]);
    setFormValues((prevState) => {
      return {
        ...prevState,
        [keyToUpdate]: dataToCopy,
      };
    });
  };

  if (!customer || !userData) {
    return <PageHeaderSkeleton actions={[{ key: 'button', element: <></> }]} subtitle={false} />;
  }

  const validateForm = (): boolean => {
    let formErrors: IAudiogramFormError[] = [];
    const validationSteps: { truthy: boolean; fieldKey: string; groupKey?: string }[] = [
      ...mainFormSections.flatMap((formGroup) => {
        const { key, fields, required } = formGroup;
        return fields
          .filter((field) => field.required && required)
          .map((field) => ({
            truthy: !isNotNullOrEmpty(formValues[key][field.key]),
            groupKey: key,
            fieldKey: field.key,
          }));
      }),
    ];

    validationSteps.forEach((check) => {
      const { truthy, fieldKey, groupKey } = check;
      if (truthy) {
        const payload = { fieldKey, ...(groupKey && { groupKey }) };
        formErrors.push(payload);
      }
    });

    if (Object.values(formErrors).length) {
      setFormErrors(formErrors);
      setFormLoading(false);
      return false;
    }
    return true;
  };

  const getAudiogramValues = () => {
    const allValues: IAddAudiogramValue = {
      leftv2: {},
      rightv2: {},
      bil: {},
    };
    Object.entries(audiogramValues).forEach(([earKey, ear]) => {
      const earValues: IAudiogramEarValues = {};
      Object.entries(ear).forEach(([typeKey, typeValue]) => {
        if (typeValue) {
          const typeValues: StringIndexable = {};
          audiogramXValues.forEach((x) => (typeValues[x] = typeValue[x] ?? null));
          earValues[typeKey] = typeValues as IAudiogramSectionValue;
        }
      });
      switch (earKey) {
        case 'left':
          allValues.leftv2 = earValues;
          break;
        case 'right':
          allValues.rightv2 = earValues;
          break;
        default:
          allValues[earKey] = earValues;
      }
    });
    return allValues;
  };

  const submitForm = async () => {
    try {
      const valid = validateForm();
      if (!valid) {
        return;
      }
      const { hearingLossCauses, details } = formValues;
      const {
        dispenser,
        testDate,
        significantOther,
        significantOtherRelation,
        durationOfLoss,
        occupation,
        familyDeafness,
        doctor,
        audiometer,
        notes,
        dob,
        fittingDate,
      } = details;
      const { noise, unilateral, asymmetrical, suddenOnset, suddenWorsening, fluctuating, vertigo, premature } =
        hearingLossCauses as IAudiogramHearingLossCauses;

      const earHealth = ['leftEarHealth', 'rightEarHealth'].map((side) => {
        const values: IAudiogramEarHealth = formValues[side];
        const { pinna, meatus, tm, discharge, earache, tinnitus, conductive } = values as IAudiogramEarHealth;
        return {
          pinna,
          meatus,
          tm,
          discharge,
          earache,
          tinnitus,
          conductive,
        };
      });

      const { uid, fullName } = userData;
      const author = { uid, fullName };

      const base = {
        uid: customer.uid,
        dispenser: dispenser!,
        hearingTestDate: testDate!,
        customer: {
          ...customer,
          dob: dob ?? customer.dob,
        },
        significantOther: significantOther!,
        significantOtherRelation: significantOtherRelation!,
        durationOfLoss: durationOfLoss!,
        occupation: occupation!,
        familyDeafness: familyDeafness!,
        doctor: doctor!,
        audiometer: audiometer?.value!,
        leftEarHealth: earHealth[0],
        rightEarHealth: earHealth[1],
        hearingLossCauses: {
          noise,
          unilateral,
          asymmetrical,
          suddenOnset,
          suddenWorsening,
          fluctuating,
          vertigo,
          premature,
        },
        audiogramValues: getAudiogramValues(),
        fittingDate: fittingDate ?? '',
        updatedAt: new Date(),
        updatedBy: author,
        notes: notes ?? '',
        isV2: true,
      };
      const audiogram = document.getElementById('audiogram-editor');
      if (!audiogram) {
        throw new Error('Audiogram image not found');
      }
      const dataUrl = await htmlToImage.toPng(audiogram, { canvasHeight: 834, canvasWidth: 631 });
      const audiogramPath = `audiogramDiagrams/${base.uid}`;
      await DocumentApiService.upload(dataUrl, 'image/png', audiogramPath);
      if (!editing) {
        const payload: IAddAudiogramRequestDto = {
          createdAt: new Date(),
          createdBy: author,
          audiogramPath,
          ...base,
        };
        const { customer, audiogramValues, ...rest } = { ...payload };
        await AudiogramApiService.set(payload, getObjectChanges(rest, {}));
      } else {
        const payload: IUpdateAudiogramRequestDto = {
          audiogramPath,
          ...base,
        };
        const { customer, audiogramValues, ...rest } = { ...payload };
        await AudiogramApiService.update(payload, getObjectChanges(rest, existingData));
      }
      if (dob) {
        const customerPayload = {
          dob,
        };
        await CustomersApiService.update(
          customer.uid,
          {
            fullName: userData.fullName,
            uid: userData.uid,
          },
          customerPayload,
          getObjectChanges(customerPayload, customer)
        );
      }
      const docPath = await AudiogramApiService.generateAudiogram({ uid: base.uid });
      setFormLoading(false);
      dialog?.openDialog(<AudiogramFormSuccessDialog path={docPath} />);
    } catch (error) {
      console.log(error);
      showErrorFlag('Audiogram creation failed', `Unable to create this audiogram, please try again`, flags);
      setFormLoading(false);
    }
  };

  const headerActions: PageElement[] = [
    {
      key: 'cancel',
      element: <SharedButton onClick={() => navigate(-1)} type='button' appearance='subtle' label='Cancel' />,
    },
    {
      key: 'submit',
      element: (
        <SharedLoadingButton
          isLoading={formLoading}
          onClick={() => {
            window.scrollTo(0, 0);
            setFormErrors([]);
            setFormLoading(true);
            setTimeout(() => {
              submitForm();
            }, 100);
          }}
          type='button'
          appearance='primary'
          label='Submit'
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Hearing test' actions={headerActions} />
      <div className='grid grid-cols-1 lg:grid-cols-4 gap-4'>
        <HearingTestDetails customer={customer} getFormInput={getFormInput} showFormError={showFormError} />
        <div className='lg:col-span-3'>
          <AudiogramDiagram
            id='audiogram-editor'
            currentMarker={currentMarker}
            currentEar={currentEar}
            onValueChange={onAudiogramValueChange}
            audiogramValues={audiogramValues}
            setCurrentEar={setCurrentEar}
            setCurrentMarker={setCurrentMarker}
            showFormError={showFormError}
            dimensions={{
              height: '834px',
              width: '631px',
            }}
          />
          <EarHealth getFormFields={getFormFields} copyEarHealthDetail={copyEarHealthDetail} />
          <HearingLossCauses getFormFields={getFormFields} />
          <div className='bg-white shadow-md rounded-md mt-4'>
            <div className='border-b headline-06 p-4'>Audiometer</div>
            <div className='p-4'>
              {getFormFields(
                [
                  {
                    key: 'audiometer',
                    control: ControlType.Select,
                    options: AudiometerOptions,
                    required: true,
                  },
                ],
                'details'
              )}
            </div>
          </div>
          <div className='bg-white shadow-md rounded-md mt-4'>
            <div className='border-b headline-06 p-4'>Notes</div>
            <div className='p-4'>
              {getFormInput(
                {
                  key: 'notes',
                  control: ControlType.TextArea,
                  required: true,
                },
                'details'
              )}
            </div>
          </div>
          <div className='pt-6 flex justify-end'>
            {headerActions.map((action) => (
              <div key={action.key} className='mr-4 last:mr-0 mb-6 lg:mb-0'>
                {action.element}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AudiogramForm;
