import { IStoredCustomer } from 'core/api/customers/customers-api-interface';
import CustomersApiService from 'core/api/customers/customers-api.service';
import { IUser } from 'core/api/users/users-api-interface';
import { CallbackDialogFormFields } from 'core/config/form-fields';
import { BookingStatus } from 'core/constants/booking-status';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { getObjectChanges } from 'core/utilities/object-helpers';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';

interface ICallbackDialog {
  customer: IStoredCustomer;
}

const CallbackDialog = ({ customer }: ICallbackDialog) => {
  // Hooks
  const dialog = useDialog();
  const { userData } = useAuthState();
  const { fullName, uid: userUid } = userData as IUser;
  const author = { fullName, uid: userUid };

  const cancelButton: IButton = {
    onClick: () => {
      dialog?.closeDialog();
    },
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const submit = async (data: any) => {
    const payload = {
      bookingStatus: BookingStatus.CALLBACK_LATER,
      callback: {
        date: data.date,
        time: data.time?.value,
      },
      statusUpdatedBy: author,
    };
    await CustomersApiService.update(customer.uid, author, payload, getObjectChanges(payload, customer));
    dialog?.closeDialog();
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4'
        onSubmit={submit}
        fields={CallbackDialogFormFields}
        buttonLabel='Submit'
        cancelButton={cancelButton}
        loading={false}
      />
    );
  };

  return <SharedDialogBase title='Arrange a callback' customContentTemplate={customContent()} showButtons={false} />;
};

export default CallbackDialog;
