import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import { ICustomer, IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { AppointmentStatus } from 'core/constants/appointment-status';
import { Permissions } from 'core/constants/permissions';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { hasPermission } from 'core/utilities/permission-helpers';
import CancelAppointmentDialog from 'modules/appointments/cancel-appointment-dialog/cancel-appointment-dialog';
import DeleteAppointmentDialog from 'modules/appointments/delete-appointment-dialog/delete-appointment-dialog';
import { useState } from 'react';
import { MoreHorizontal } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import CalendarAppointmentDetailDialog from '../calendar-appointment-detail-dialog/calendar-appointment-detail-dialog';

interface ISharedCalendarAppointmentDropdownMenu {
  showViewCustomerButton?: boolean;
  customer: IStoredCustomer | ICustomer;
  appointment: IAppointment;
  subtle?: boolean;
  alwaysOpen?: boolean;
  className?: string;
}

const SharedCalendarAppointmentDropdownMenu = ({
  showViewCustomerButton = false,
  customer,
  appointment,
  subtle = true,
  alwaysOpen = false,
  className = '',
}: ISharedCalendarAppointmentDropdownMenu) => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const [isOpen, setIsOpen] = useState(false);
  const { userPermissions } = useAuthState();

  const getVisibility = () => {
    if (alwaysOpen) return true;
    return isOpen;
  };

  const canUpdate =
    appointment.status !== AppointmentStatus.CANCELLED &&
    hasPermission(Permissions.APPOINTMENTS_UPDATE, userPermissions);

  const dropdownItems = [
    {
      visible: showViewCustomerButton,
      onClick: () => navigate(`/customers/${customer.uid}`),
      label: 'View customer',
    },
    {
      visible: true,
      onClick: () => dialog?.openDialog(<CalendarAppointmentDetailDialog appointment={appointment} />),
      label: 'View details',
    },
    {
      visible: canUpdate,
      onClick: () => navigate(`/appointments/edit/${appointment.uid}`),
      label: 'Edit',
    },
    {
      visible: canUpdate,
      onClick: () => dialog?.openDialog(<CancelAppointmentDialog appointment={appointment} />),
      label: 'Cancel',
    },
    {
      visible: hasPermission(Permissions.APPOINTMENTS_DELETE, userPermissions),
      onClick: () =>
        dialog?.openDialog(<DeleteAppointmentDialog uid={appointment.uid} customerUid={appointment.customer.uid} />),
      label: 'Delete',
    },
    {
      visible: hasPermission(Permissions.ORDER_CREATE, userPermissions) && !appointment.prescribed,
      onClick: () => navigate(`/orders/new?appointment=${appointment.uid}`),
      label: 'Create order',
    },
  ];

  return (
    <div className={`group-hover:block ${getVisibility() ? 'block' : 'hidden'} z-10 ${className}`}>
      <DropdownMenu
        isOpen={isOpen}
        onOpenChange={() => {
          setIsOpen(!isOpen);
        }}
        trigger={({ triggerRef, ...props }) => (
          <Button
            {...props}
            iconBefore={<MoreHorizontal className={subtle ? 'text-white' : ''} />}
            appearance={subtle ? 'subtle' : 'default'}
            ref={triggerRef}
          />
        )}
      >
        <DropdownItemGroup>
          {dropdownItems
            .filter((item) => item.visible)
            .map((item) => (
              <DropdownItem key={item.label} onClick={item.onClick}>
                {item.label}
              </DropdownItem>
            ))}
        </DropdownItemGroup>
      </DropdownMenu>
    </div>
  );
};

export default SharedCalendarAppointmentDropdownMenu;
